import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 10%;
  width: 100vw;
`;

export const Content = styled.div`
  display: flex;
  height: auto;
  max-width: 100vw;
  margin-left: auto;
  margin-right: auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    a {
      font-size: 16px;
      margin: 10px;
      font-weight: bold;
      color: #333;

      &:hover {
        transition: color 0.3s;
        color: #000;
      }
    }
  }
`;
