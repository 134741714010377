/* eslint-disable react/prop-types */
import React, { useState, forwardRef } from 'react';
import Papa from 'papaparse';

import { toast } from 'react-toastify';
import { Div } from './styles';
import { Container } from '../styles';

// eslint-disable-next-line no-unused-vars
const Step2 = forwardRef((props, ref) => {
  const initialData = JSON.parse(sessionStorage.getItem('StandsAndPlots'));

  const [Objetocsv, SetObjectocsv] = useState(initialData || []);

  // const projectSelected = sessionStorage.getItem('projectToRegister');

  function handleSubmit(event) {
    const { files } = event.target;
    Papa.parse(files[0], {
      header: true,
      complete(results) {
        results.data.pop();
        SetObjectocsv(results.data);
        sessionStorage.setItem('StandsAndPlots', JSON.stringify(results.data));

        // results.data.map((data, index) =>
        //   Objetocsv.push({
        //     ...data,
        //   })
        // );
      },
    });
  }

  function handleSalve() {
    // Esta é uma função do componente pai do Switch stepper
    if (Objetocsv.length === 0 || null) {
      toast.error('Você precisa importar os dados');
    } else {
      props.handleAdd();
    }
  }

  return (
    <>
      <Container>
        <div>
          <label htmlFor="file-upload">
            <div>
              <h3>Selecione um arquivo .csv</h3>
            </div>
            <input
              id="file-upload"
              type="file"
              accept=".csv,.xlsx,.xls"
              onChange={handleSubmit}
              placeholder="Importe as informações"
            />
          </label>
        </div>
        <Div>
          <button className="verde" type="submit" onClick={handleSalve}>
            <h3>Salvar</h3>
          </button>
        </Div>
        <br />
        <div>
          <table>
            <thead>
              <tr>
                <th>Talhão</th>
                <th>Área do Talhão (ha)</th>
                <th>Parcela</th>
                <th>Área da Parcela (m²)</th>
                <th>Latitude</th>
                <th>Longitude</th>
              </tr>
            </thead>
            <tbody>
              {Objetocsv.map(element => (
                <>
                  <tr>
                    <td>{element.nomeTal}</td>
                    <td>{element.areaTal}</td>
                    <td>{element.nomeParc}</td>
                    <td>{element.areaParc}</td>
                    <td>{element.latitude}</td>
                    <td>{element.longitude}</td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    </>
  );
});

export default Step2;
