import React, { useEffect, useState } from "react";

import MUIDataTable from "mui-datatables";

// import GoogleMaps from '~/components/GoogleMaps';
import { toast } from "react-toastify";

import Leaflet from "~/components/Leaflet";

import Loading from "~/components/Loading";
import api from "~/services/api";

import { Container } from "./styles";

const Toastoptions = {
  autoClose: 3000,
  hideProgressBar: false,
  pauseOnHover: false,
  closeOnClick: true,
};

function ProjectsList() {
  const [loading, setLoading] = useState(false);
  const [projectsList, setProjectsList] = useState([]);
  // const [rowsSelected, setRowsSelected] = useState([]);

  /**
   * Listener not found LEAFLET
   *
   * Tentar usar o sessiondata para manter esses parâmetros perdidos ao sair da subpágina de listagem
   */
  const [boundsData, setBoundsData] = useState([]);
  const [farmId, setFarmId] = useState();

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    selectableRows: "none",
    rowHover: true,
    // customRowRender(data, dataIndex, rowIndex) {
    //   return (
    //     <>
    //       <table>
    //         <th>Vai dar certo</th>
    //       </table>
    //       <Table />
    //     </>
    //   );
    // },
    // setRowProps: (row, dataIndex, rowIndex) => {
    //   return {
    //     style: { background: '#f0f' },
    //   };
    // },
    selectableRowsOnClick: false,
    fixedSelectColumn: false,
    // rowsSelected,
    onRowClick: (rowsSelectedData, rowsSelected) => {
      setBoundsData(rowsSelectedData);
      setFarmId(rowsSelected.dataIndex);
      toast.success(
        `Projeto "${rowsSelectedData[0]}" selecionado!`,
        Toastoptions
      );
    },
    // eslint-disable-next-line no-shadow
    // onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
    //   // console.log(rowsSelectedData, allRows, rowsSelected);
    //   // console.log(rowsSelectedData);
    //   setRowsSelected(rowsSelected);
    //   setNewData(rowsSelectedData);
    // },
    selectToolbarPlacement: "none",
    fixedHeader: false,
    rowsPerPage: 5,
    rowsPerPageOptions: [5, 10, 15],
    download: false,
    // downloadOptions: {
    //   filterOptions: {
    //     useDisplayedRowsOnly: true,
    //     useDisplayedColumnsOnly: true,
    //   },
    // },
    pagination: true,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro para o projeto.",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "TUDO",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
      selectedRows: {
        text: "Linhas selecionadas",
        delete: "Deletar",
        deleteAria: "Deletar linhas selecionadas",
      },
    },
  };

  const columns = [
    {
      name: "projeto",
      label: "Projeto",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "produtor",
      label: "Produtor",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "município",
      label: "Município",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "estado",
      label: "Estado",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "latitude",
      label: "Latitude º",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "longitude",
      label: "Longitude º",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  useEffect(() => {
    setLoading(true);
    async function loadProjects() {
      const alreadyLoaded = JSON.parse(sessionStorage.getItem("ProjectsInfo"));
      const refreshedTime =
        JSON.parse(sessionStorage.getItem("ProjectsRefreshTime")) || new Date();
      let now = new Date().valueOf();

      if (alreadyLoaded && now <= refreshedTime) {
        setProjectsList(alreadyLoaded);
        setLoading(false);
      } else {
        const response = await api.get("/myprojects");

        const data = response.data.map((project) => ({
          ...project,
        }));

        setProjectsList(data);
        setLoading(false);

        const loadedTime = new Date();
        const refresh = loadedTime.setTime(
          loadedTime.valueOf() + 1000 * 60 * 5
        );
        sessionStorage.setItem("ProjectsRefreshTime", refresh);
        sessionStorage.setItem("ProjectsInfo", JSON.stringify(response.data));

        setLoading(false);
      }
    }
    loadProjects();
  }, []);

  return (
    <>
      <Container>
        <div className="table">
          <div>
            <MUIDataTable
              title="Meus Projetos"
              data={projectsList.map((d) => {
                return [
                  d.projectname,
                  d.projectowner,
                  d.projectcity,
                  d.projectstate,
                  d.projectcoordx.toFixed(4),
                  d.projectcoordy.toFixed(4),
                ];
              })}
              columns={columns}
              options={options}
            />
          </div>
        </div>
        {!loading ? (
          <Leaflet
            // aqui eu passo função|dados do pai para o filho leaflet
            boundsId={farmId}
            boundsLat={parseFloat(boundsData[4])}
            boundsLng={parseFloat(boundsData[5])}
            height="500px"
            width="850px"
          />
        ) : (
          ""
        )}
      </Container>
      <Loading loading={loading} />
    </>
  );
}

export default ProjectsList;
