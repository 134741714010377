import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  @media only screen and (max-width: 768px) {
    display: block;
    max-height: 70vh;
    overflow-Y: scroll;
    ::-webkit-scrollbar {
    display: none;
    }
  }
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  width: 90vw;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  max-width: 100vw;
  max-height: 70vh;
  overflow-y: scroll;


  /* overflow-x: hidden; */

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 112, 0, 1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${darken(0.03, 'rgba(0, 112, 0, 0.7)')};
  }

  div {
    margin-left: auto;
    margin-right: auto;
    h6 {
      font-weight: bold;
    }
  }

  table {
    display: block;
    width: auto;
    /* height: 20%; */
    text-align: center;

    span {
      font-weight: bold;
      font-size: 20px;
    }

    div {
      text-align: center;
    }
  }
  tr,
  th,
  td {
    /* text-align: center;
    vertical-align: center;
    font-size: 16px;
    border: 1px solid black;
    padding: 5px; */
  }

  /* ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 112, 0, 1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${darken(0.03, 'rgba(0, 112, 0, 0.7)')};
  } */
`;
