import L from 'leaflet';

const myIcon = L.icon({
  iconUrl: require('../../assets/forest-pine-svgrepo-com.svg'),
  // iconUrl: 'https://www.svgrepo.com/svg/92010/forest',
  iconSize: [24, 24],
  iconAnchor: [10, 10],
  // popupAnchor: null,
  // shadowUrl: null,
  // shadowSize: null,
  // shadowAnchor: null,
});

export default myIcon;
