import React from "react";
import { Form, Input } from "@rocketseat/unform";
import * as Yup from "yup";
// import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import logo from "~/assets/trees.png";
import history from "~/services/history";

import { Container } from "./styles";

import api from "~/services/api";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Insira um e-mail válido")
    .required("O e-mail é obrigatório"),
});

export default function ForgotPassword() {
  // const recaptchaRef = useRef();

  async function handleButton(dataForm) {
    // const recaptchaValue = recaptchaRef.current.getValue();
    // if (recaptchaValue) {
    //   dataForm.recaptchaValue = recaptchaValue;

    try {
      await api.put(`/managereset`, dataForm);
      toast.success("Solicitação de nova senha enviada. Verifique seu e-mail.");
      await new Promise((resolve) => setTimeout(resolve, 5000));
      history.push("/");
    } catch (err) {
      toast.error("Não é mais possível atualizar esta senha");
    }
    // } else {
    //   toast.error("Confime que você não é um robô.");
    // }
  }

  return (
    <Container>
      <Form
        schema={schema}
        onSubmit={handleButton}
        data-netlify-recaptcha="true"
      >
        <img src={logo} alt="GoBarber" />
        <h2>ForTrees</h2>
        <Input
          name="email"
          type="email"
          placeholder="Informe seu e-mail cadastrado"
        />
        {/* <CaptchaContainer>
          <ReCAPTCHA
            className="ReCAPTCHA"
            sitekey="6Lda8tshAAAAAErCIEBDaDcV5Ki7o31CT-RhCm3C"
            // onChange={onChange}
            ref={recaptchaRef}
            theme="dark"
          />
        </CaptchaContainer> */}

        <button type="submit">Solicitar mudança de senha</button>
      </Form>
    </Container>
  );
}
