import styled from "styled-components";
import { darken } from "polished";

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  width: auto;
  max-width: 100vw;
  height: auto;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  margin: 0 auto;
  height: auto;


  select {
    background: rgba(0, 0, 0, 0.07);
    border: 0;
    border-radius: 4px;
    height: 44px;
    color: #000;
    margin: 10px;
    padding: 5px;
  }

  div {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;s

    max-width: 900px;
    padding: 5px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    box-shadow: -3px 2px 3px 1px #aaa;
    border-radius: 5px;
    overflow: auto;


    ::-webkit-scrollbar {
      display: none;
    }

  }


  form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;

    div {
      width: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      padding: 0;
      box-shadow: none;
      margin: 0;
      border: 5px;
      padding: 0;
      background-color: rgb(245, 245, 245);

      span {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 15px;
        display: flex
        flex-direction: column;
        text-align: left;
        color: red;
      }
    }
  button {
    min-width: 250px;
    max-width: 90vw;
    height: 44px;
    background: rgba(0, 112, 0, 0.7);
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, "rgba(0, 212, 0, 1)")};
    }
  }



  }

  img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3);
    background: #eee;
  }
  h2 {
    width: 100%;
  }
  table {
    display: inline-block;
    max-width: 100vw;
    margin: 0 auto;
    padding-bottom: 100px;
    border-spacing: 0px;

    span {
      font-weight: bold;
      font-size: 20px;
    }

  }

  tr:hover {
    background-color: rgb(245, 245, 245);
  }
  tr,
  th,
  td {
    width: 100%;
    margin: 0 auto;
    padding: 15px;
    border: 0px;
    text-align: center;
    vertical-align: center;
    font-size: 24px;
    border-bottom: 1px solid #ccc;

    button.delete {
    height: 40px;
    width: 40px;
    font-weight: bold;
    background: white ;
    color: black;
    border: 0;
    border-radius: 4px;
    font-size: 24px;
    transition: background 0.2s;
      &:hover {
        color: white
        background: ${darken(0.03, "rgba(50, 50, 50, 1)")};
      }

    }
  }

  th {
    font-size: 18px;
    padding-top: 25px;

  }

  td {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;

  }


`;
