import axios from "axios";

const api = axios.create({
  baseURL: "https://www.backfortrees.datagro.tec.br", // BR Domain
  // baseURL: "http://192.168.15.4:3333", // BR Domain
  // baseURL: "http://167.99.6.90", // DigitalOcean
  // baseURL: "24.199.65.218", // DigitalOcean ReservedIp
  // baseURL: 'http://localhost:3333',
  // baseURL: 'http://192.168.43.168:3333',
  // baseURL: 'http://10.3.38.31:3333',
  // baseURL: 'http://172.17.0.1:3333',
  // baseURL: 'http://192.168.0.11:3333',
});

export default api;
