/* eslint-disable no-plusplus */
import React, { useState, useRef } from "react";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Container } from "./styles";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";

function InventoryAnalysis() {
  const funcoesFilho1 = useRef();

  // const [Objetocsv, SetObjectocsv] = useState([]);
  const [stepp, setStepp] = useState(0);
  const [projectName, setProjectName] = useState("");

  // const dataStepp1 = {
  //   owner: '',
  //   projectname: '',
  //   coordx: '',
  //   coordy: '',
  // };

  // function setProjectData(projectname, projectowner) {
  //   dataStepp1.owner = projectowner;
  //   dataStepp1.projectname = projectname;
  // }

  // function handleAddProject() {
  //   // Aqui consigo usar uma função do filho no pai
  //   funcoesFilho1.current.projectAdd();
  //   // Dados do filho no pai
  //   console.log(funcoesFilho1.current.teste);
  //   setStepp(funcoesFilho1.current.newstep);
  // }

  // console.log(`${projectName} ...`);

  // console.log(funcoesFilho1.current);

  function print() {
    // console.log(funcoesFilho1);
  }

  function handleAddStep() {
    if (stepp < 3) {
      setStepp(stepp + 1);
    }
  }

  function handleSubStep() {
    if (stepp > 0) {
      setStepp(stepp - 1);
    }
  }

  function componentSteppeRender(step) {
    switch (step) {
      case 0:
        // Aqui consigo passar uma função|data do pai pro filho
        return (
          <Step1
            ref={funcoesFilho1}
            // projectdata={dataStepp1}
            projectName={projectName}
            setProjectName={setProjectName}
            // setProjectData={setProjectData}
            newdata={stepp}
            print={print}
            handleAdd={handleAddStep}
          />
        );

      case 1:
        return <Step2 handleAdd={handleAddStep} />;

      case 2:
        return <Step3 handleAdd={handleAddStep} />;

      case 3:
        return <Step4 />;

      default:
        return <div>Default</div>;
    }
  }

  const steps = [
    "Análise da consistência dos dados",
    "Ajuste e validação de um modelo Hipsométrico",
    "Ajuste e validação de um modelo de Afilamento",
    "Estimativas de Produção",
  ];

  // function handleProgress(progress, event) {}

  return (
    <>
      <Container>
        <Box sx={{ width: "100%", marginLeft: "auto", marginRight: "auto" }}>
          <Stepper activeStep={stepp} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        <div>
          <button className="stepper" onClick={handleSubStep} type="submit">
            Voltar
          </button>
        </div>
        <div>{componentSteppeRender(stepp)}</div>
      </Container>
    </>
  );
}

export default InventoryAnalysis;
