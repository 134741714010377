import React from 'react';
import { NavLink } from 'react-router-dom';

import { Container, Content } from './styles';

function ServicesNavigator() {
  return (
    <>
      <Container>
        <Content>
          <nav>
            <NavLink
              to="/services/list"
              activeStyle={{
                color: 'black',
                borderBottom: 'solid 1px rgba(0, 0, 0, 0.9)',
              }}
            >
              Lista de serviços
            </NavLink>
            <NavLink
              to="/services/newservice"
              activeStyle={{
                color: 'black',
                borderBottom: 'solid 1px rgba(0, 0, 0, 0.9)',
              }}
            >
              Cadastrar novo serviço
            </NavLink>
          </nav>
        </Content>
      </Container>
    </>
  );
}

export default ServicesNavigator;
