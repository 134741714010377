/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import { parseISO, format } from "date-fns";
import pt from "date-fns/locale/pt";

import MUIDataTable from "mui-datatables";

import { toast } from "react-toastify";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { MdDelete } from "react-icons/md";

import Loading from "~/components/Loading";
import api from "~/services/api";

import { Container } from "./styles";

function Myservices() {
  const [loading, setLoading] = useState(false);
  const [servicesTable, setServicesTable] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [reload, setReload] = useState(false);
  const [serviceToDelete, setServiceToDelete] = useState();

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    fixedSelectColumn: false,
    fixedHeader: true,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    pagination: true,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro de serviços para os coletores.",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "TUDO",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
      selectedRows: {
        text: "Linhas selecionadas",
        delete: "Deletar",
        deleteAria: "Deletar linhas selecionadas",
      },
    },
  };

  const columns = [
    {
      label: "Projeto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Produtor",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Responsável",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Data da ordem",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Status",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Data da atualização",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "",
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  function CheckListOfServices(data) {
    const dataTable = [];

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      for (let j = 0; j < element.services.length; j++) {
        const subelement = element.services[j];

        const dataItem = {
          id: subelement.id,
          project: element.projectname,
          owner: element.projectowner,
          collector: subelement.collectors.name,
          serviceDate: format(parseISO(subelement.created_at), "dd/MM/yyyy", {
            locale: pt,
          }),
          serviceStatus: subelement.status,
          serviceUpdate: format(parseISO(subelement.updated_at), "dd/MM/yyyy", {
            locale: pt,
          }),
        };
        dataTable.push(dataItem);
      }
    }
    setServicesTable(dataTable);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOpen = (selectedId) => {
    setOpen(true);

    setServiceToDelete(selectedId);
  };

  useEffect(() => {
    setLoading(true);
    async function loadServices() {
      const alreadyLoaded = JSON.parse(sessionStorage.getItem("ServicesInfo"));
      const refreshedTime =
        JSON.parse(sessionStorage.getItem("ServicesRefreshTime")) || new Date();
      let now = new Date().valueOf();

      if (alreadyLoaded && now <= refreshedTime && !reload) {
        CheckListOfServices(alreadyLoaded);
        setLoading(false);
      } else {
        const response = await api.get("/services");

        const data = response.data.map((service) => ({
          ...service,
        }));

        const loadedTime = new Date();
        const refresh = loadedTime.setTime(
          loadedTime.valueOf() + 1000 * 60 * 5
        );
        sessionStorage.setItem("ServicesRefreshTime", refresh);
        sessionStorage.setItem("ServicesInfo", JSON.stringify(data));

        CheckListOfServices(data);
        setLoading(false);
      }
    }
    loadServices();
  }, [reload]);

  async function handleDelete() {
    setLoading(true);
    try {
      await api.delete(`/services/${serviceToDelete}`);

      setOpen(false);

      setLoading(false);
      setReload(true);
      toast.success("Serviço cancelado com sucesso!");
    } catch (err) {
      setLoading(false);
      toast.error(`Erro inesperado`);
    }
  }

  return (
    <>
      <Container>
        <Loading loading={loading} />

        <MUIDataTable
          title="Meus Serviços"
          data={servicesTable.map((s) => {
            return [
              s.project,
              s.owner,
              s.collector,
              s.serviceDate,
              s.serviceStatus,
              s.serviceUpdate,
              s.serviceStatus === "Enviado" ? (
                <button type="submit" onClick={() => handleClickOpen(s.id)}>
                  <MdDelete size={15} />
                </button>
              ) : (
                ""
              ),
            ];
          })}
          columns={columns}
          options={options}
        />
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: "white",
              boxShadow: "inherit",
              // color: 'blue',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Têm certeza que deseja cancelar este serviço?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Este prestador não poderá mais acessar esta ordem de serviço.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleDelete}>Deletar</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default Myservices;
