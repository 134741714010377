import React from "react";

import { Container } from "./styles";
import logo from "~/assets/trees.png";

function Loading({ loading }) {
  return (
    <>
      <Container type={loading ? "open" : "close"}>
        <div>
          <img src={logo} alt="ForTrees" width="100px" />
          <h1>Carregando...</h1>
        </div>
      </Container>
    </>
  );
}

export default Loading;
