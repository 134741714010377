import React, { useEffect, useState, useCallback, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { BiTachometer } from "react-icons/bi";
import { FaTractor } from "react-icons/fa";
import { IoMdPeople } from "react-icons/io";
import { MdPendingActions } from "react-icons/md";
import { AiOutlineDatabase } from "react-icons/ai";
import { RiMenuUnfoldLine } from "react-icons/ri";
import { TbDeviceDesktopAnalytics } from "react-icons/tb";

import api from "~/services/api";

import { signOut } from "~/store/modules/auth/actions";

import history from "~/services/history";

import { Container, Content, Profile, ButtonIcon } from "./styles";
import logo from "~/assets/trees.png";

function Header() {
  const profile = useSelector((state) => state.manager.profile);
  const signindate = useSelector((state) => state.manager.profile.signindate);
  const sessionToken = useSelector(
    (state) => state.manager.profile.sessionToken
  );

  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  const dispatch = useDispatch();

  const handleSignOut = useCallback(() => {
    if (isMounted.current) {
      dispatch(signOut());
      setNeedsLogout(true);
    }
  }, [dispatch]);

  const [sideBar, setSideBar] = useState(false);
  const toogleSidebar = () => setSideBar(!sideBar);

  const [needsLogout, setNeedsLogout] = useState(false);

  useEffect(() => {
    if (needsLogout) {
      history.push("/");
    }
  }, [needsLogout]);

  const [bar, setBar] = useState(<RiMenuUnfoldLine />);

  function changeToogleSign() {
    if (sideBar === true) {
      setBar(<RiMenuUnfoldLine />);
    } else {
      setBar(<RiMenuUnfoldLine />);
    }
    toogleSidebar();
    return bar;
  }

  const checkSessionToken = useCallback(async () => {
    if (sessionToken && isMounted.current) {
      const body = {
        userToken: sessionToken,
      };
      const lastToken = await api.post("/managersessioncheck", body);

      if (isMounted.current) {
        if (!(sessionToken === lastToken.data.token)) {
          handleSignOut();
          history.push("/");
        }
      }
    } else if (isMounted.current) {
      handleSignOut();
      history.push("/");
    }
  }, [sessionToken, handleSignOut]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (isMounted.current) {
        checkSessionToken();
      }
    }, 300000); // Checks the token every 5 minutes

    return () => {
      isMounted.current = false;
      clearInterval(intervalId);
    };
  }, [checkSessionToken]);

  const SignInDateExpired = useCallback(() => {
    if (signindate && isMounted.current) {
      const dateExpiration = signindate + 1000 * 60 * 60 * 24;
      const dateNow = Date.now();
      if (isMounted.current) {
        if (dateNow > dateExpiration) {
          handleSignOut();
          history.push("/");
        }
      }
    } else if (isMounted.current) {
      handleSignOut();
      history.push("/");
    }
  }, [signindate, handleSignOut]);

  useEffect(() => {
    if (isMounted.current) {
      SignInDateExpired();
      checkSessionToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkSessionToken]);

  return (
    <>
      <Container type={sideBar === true ? "open" : "close"}>
        <Content>
          <nav>
            <br />
            <Link
              to="/dashboard"
              style={{
                display: "block",
                marginRight: "20px",
                marginLeft: "20px",
              }}
            >
              <img src={logo} alt="ForTrees" />
            </Link>

            <NavLink
              to="/dashboard"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(255, 255, 255, 0.9)",
              }}
              style={{
                textAlign: "center",
                margin: "8px",
              }}
            >
              <BiTachometer size={20} />
              <h4>Controle</h4>
            </NavLink>
            <NavLink
              to="/projectsmanager"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(255, 255, 255, 0.9)",
              }}
              style={{
                textAlign: "center",
                margin: "8px",
              }}
            >
              <FaTractor size={20} />
              <h4>Projetos</h4>
            </NavLink>
            <NavLink
              to="/collectors"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(255, 255, 255, 0.9)",
              }}
              style={{
                textAlign: "center",
                margin: "8px",
              }}
            >
              <IoMdPeople size={20} />
              <h4>Coletores</h4>
            </NavLink>
            <NavLink
              to="/services"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(255, 255, 255, 0.9)",
              }}
              style={{
                textAlign: "center",
                margin: "8px",
              }}
            >
              <MdPendingActions size={20} />
              <h4>Serviços</h4>
            </NavLink>
            <NavLink
              to="/mydata"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(255, 255, 255, 0.9)",
              }}
              style={{
                textAlign: "center",
                margin: "8px",
              }}
            >
              <AiOutlineDatabase size={20} />
              <h4>Dados</h4>
            </NavLink>
            <NavLink
              to="/analytics"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(255, 255, 255, 0.9)",
              }}
              style={{
                textAlign: "center",
                margin: "8px",
              }}
            >
              <TbDeviceDesktopAnalytics size={20} />
              <h4>Análises</h4>
            </NavLink>
            <aside>
              <Profile>
                <Link to="/profile">
                  <img
                    src={
                      profile.avatar == null
                        ? "https://i.pinimg.com/originals/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg"
                        : profile.avatar.url
                    }
                    alt="Arthur Chaves"
                    style={{
                      width: "60px",
                      height: "60px",
                    }}
                  />
                </Link>
                <div>
                  <strong>{profile ? profile.name : ""}</strong>
                  <NavLink
                    to="/profile"
                    activeStyle={{
                      color: "black",
                      borderBottom: "solid 1px rgba(255, 255, 255, 0.9)",
                    }}
                    style={{
                      paddingLeft: "0px",
                      marginLeft: "0px",
                      borderBlockStart: "0px",
                    }}
                  >
                    Meu perfil
                  </NavLink>
                </div>
              </Profile>
            </aside>
          </nav>
        </Content>
      </Container>
      <div>
        <ul className="toogle">
          <ButtonIcon
            type={sideBar ? "open" : "close"}
            onClick={changeToogleSign}
          >
            <button type="submit">{bar}</button>
          </ButtonIcon>
          {/* <a href="/">
            <img src={fotoPerfil} alt=""/>
          </a> */}
        </ul>
      </div>
    </>
  );
}

export default Header;
