import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BsFillPersonCheckFill } from "react-icons/bs";
// import ReCAPTCHA from "react-google-recaptcha";
import { Container } from "./styles";
import logo from "~/assets/trees.png";
import history from "~/services/history";

import api from "~/services/api";

function UserValidation() {
  const { token } = useParams();
  const [message, setMessage] = useState(false);
  // const recaptchaRef = useRef();

  async function Validar() {
    // const recaptchaValue = recaptchaRef.current.getValue();
    // if (recaptchaValue) {
    try {
      await api.put(`/collectorvalidation/${token}`);
      setMessage(true);
      toast.success("Conta Validada com sucesso!");
      await new Promise((resolve) => setTimeout(resolve, 5000));
      history.push("/");
    } catch (err) {
      toast.error("Não foi possível validar esta conta!");
    }
    // } else {
    //   toast.error("Prove que você não é um robô.");
    // }
  }
  return (
    <>
      {message ? (
        <>
          <div>
            <h3>
              <BsFillPersonCheckFill /> Conta validada com sucesso!!!
            </h3>
          </div>
        </>
      ) : (
        <Container>
          <img src={logo} alt="GoBarber" />
          <h2>ForTrees</h2>
          {/* <div className="CaptchaContainer">
            <ReCAPTCHA
              className="ReCAPTCHA"
              sitekey="6LccLdYhAAAAAMGd9YoXtl1q43y5Qx9GMoN_MCnK"
              // onChange={onChange}
              ref={recaptchaRef}
              theme="dark"
            />
          </div> */}
          <button onClick={Validar} type="submit">
            Validar Conta
          </button>
        </Container>
      )}
    </>
  );
}

export default UserValidation;
