/* eslint-disable no-var */
import React, { useState, useEffect } from "react";

import PropTypes from "prop-types";

import {
  MapContainer,
  Marker,
  TileLayer,
  Popup,
  Tooltip,
  Pane,
  useMapEvents,
  LayersControl,
} from "react-leaflet";

// eslint-disable-next-line import/no-unresolved
import "leaflet/dist/leaflet.css";

import { toast } from "react-toastify";

import api from "~/services/api";

import Container from "./styles";

import myIcon from "./icon";
import myIcon2 from "./icon2";
// import myIcon3 from './icon3';

function Leaflet({ height, boundsLat, boundsLng, boundsId }) {
  // eslint-disable-next-line no-unused-vars
  // const [farms, setFarms] = useState(data);
  const [projectsList, setProjectsList] = useState([]);
  const [projectPlots, setProjectPlots] = useState([]);

  // const arrayOfProjects = data.map(farm => ({
  //   lat: farm.projectcoordx,
  //   lng: farm.projectcoordy,
  // }));

  const [bounds, setBounds] = useState([
    { lat: 4, lng: -73 },
    { lat: -32, lng: -32 },
  ]);

  useEffect(() => {
    async function loadProjects() {
      const response = await api.get("/myprojects");

      const localData = response.data.map((project) => ({
        ...project,
      }));

      setProjectsList(localData);
    }
    loadProjects();
  }, []);

  function addPlotsCoords(id) {
    const array = [];
    var northEast = { lat: -100.0, lng: 0.0 };
    var southWest = { lat: 0.0, lng: -90.0 };

    const projectSelected = projectsList.filter((project) => project.id === id);

    if (projectSelected[0].stands.length >= 1) {
      try {
        projectSelected[0].stands.forEach((item) => {
          item.plots.forEach((subitem) => {
            if (subitem.coordx > northEast.lat) {
              northEast.lat = subitem.coordx;
              northEast.lng = subitem.coordy;
            }
            if (subitem.coordy > southWest.lng) {
              southWest.lat = subitem.coordx;
              southWest.lng = subitem.coordy;
            }

            array.push({
              name: subitem.plotname,
              area: subitem.plotarea,
              lat: subitem.coordx,
              lng: subitem.coordy,
            });
          });

          setProjectPlots(array);
          // console.log(array);

          const LatLngBounds = { southWest, northEast };

          setBounds(LatLngBounds);
        }, toast.success(`Projeto ${projectSelected[0].projectname} selecionado!`));
      } catch (err) {
        toast.error(`Erro`);
      }
    } else {
      toast.error(
        `Este Projeto "${projectSelected[0].projectname}" não possui parcelas cadastradas`
      );
    }
  }

  useEffect(() => {
    function arrowHook() {
      var northEast = {
        lat: boundsLat,
        lng: boundsLng,
      };
      var southWest = {
        lat: boundsLat,
        lng: boundsLng,
      };

      const latlng = { northEast, southWest };
      setBounds(latlng);
    }
    arrowHook();
  }, [boundsLat, boundsLng, boundsId]);

  function ZoommToProject() {
    const map = useMapEvents({
      keypress: () => {
        map.flyToBounds([
          {
            lat: bounds.northEast.lat ? bounds.northEast.lat : 4,
            lng: bounds.northEast.lng ? bounds.northEast.lng : -78,
          },
          {
            lat: bounds.southWest.lat ? bounds.southWest.lat : -32,
            lng: bounds.southWest.lng ? bounds.southWest.lng : -32,
          },
        ]);
      },
    });
    return <></>;
  }
  // function ZoommToFarm() {
  //   const map = useMapEvents({
  //     keypress: () => {
  //       map.flyToBounds([
  //         { lat: farmsBounds.northEast.lat, lng: farmsBounds.northEast.lng },
  //         { lat: farmsBounds.southWest.lat, lng: farmsBounds.southWest.lng },
  //       ]);
  //     },

  //     // tooltipopen: () => {
  //     //   map.flyToBounds([
  //     //     { lat: bounds.northEast.lat, lng: bounds.northEast.lng },
  //     //     { lat: bounds.southWest.lat, lng: bounds.southWest.lng },
  //     //   ]);
  //     // },
  //   });
  //   return <></>;
  // }

  // function MyComponentInsideMap() {
  //   return (
  //     <>
  //       <div>
  //         <button type="submit" onClick={ZoommToProject}>
  //           Zoom In
  //         </button>
  //       </div>
  //     </>
  //   );
  // }

  // const filteredFarms =
  //   search.length > 0
  //     ? farms.filter(farm => farm.projectname.includes(search))
  //     : [];

  // setFarms(JSON.parse(sessionStorage.getItem('farmsPosisiton')));

  return (
    <Container>
      <MapContainer
        attributionControl
        // center={center}
        // Demarcar os limites (bounds) máximos e minimos para latitude e longitude dos projetos
        // Verificar o que fazer no primeiro carregamento sem dados
        bounds={bounds}
        // zoom={zoom}
        scrollWheelZoom
        style={{
          height,
          width: "100%",
          marginLeft: 0,
          marginRight: 0,
          borderRadius: "5px",
          maxWidth: "100vw",
          zIndex: 1,
        }}
      >
        {/* <TileLayer
          // attribution='&copy; <a href="https://www.openstreetmap.org/copyright"></a> contributors'
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/Specialty/DeLorme_World_Base_Map/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Transportation/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Reference_Overlay/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places_Alternate/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}"
          // url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
          url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          maxZoom={17.5}
        /> */}

        {/* <Pane>
          <TileLayer
            opacity={0.5}
            url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            zIndex={2}
            maxZoom={15}
          />
        </Pane> */}
        <Pane>
          <TileLayer
            url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
            zIndex={1}
            opacity={1}
            maxZoom={17}
          />
        </Pane>

        <LayersControl>
          {/* <LayersControl.Overlay name="Satellite">
            <Pane>
              <TileLayer
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                zIndex={1}
                opacity={1}
                maxZoom={17}
              />
            </Pane>
          </LayersControl.Overlay> */}
          <LayersControl.Overlay name="Divisas">
            <Pane>
              <TileLayer
                opacity={1}
                url="http://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places_Alternate/MapServer/tile/{z}/{y}/{x}"
                zIndex={1}
                maxZoom={15}
              />
            </Pane>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Rodovias">
            <Pane>
              <TileLayer
                opacity={1}
                style={{ color: "#000" }}
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
                zIndex={1}
                maxZoom={15}
              />
            </Pane>
          </LayersControl.Overlay>
          <LayersControl.Overlay name="Topografia">
            <Pane>
              <TileLayer
                opacity={1}
                url="http://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                zIndex={1}
                maxZoom={15}
              />
            </Pane>
          </LayersControl.Overlay>
        </LayersControl>

        <Pane>
          {projectsList ? (
            projectsList.map((item) => (
              <div key={item.id}>
                <Marker
                  icon={myIcon}
                  zIndex={2}
                  key={item.projectname}
                  position={[item.projectcoordx, item.projectcoordy]}
                  eventHandlers={{
                    click: () => {
                      addPlotsCoords(item.id);
                    },
                  }}
                >
                  <Popup>
                    <Tooltip>Clique para ver as parcelas</Tooltip>
                    <div>
                      <strong>Projeto</strong>: {item.projectname}
                    </div>
                    <div>
                      <strong>Proprietário</strong>: {item.projectowner}
                    </div>
                    <div>
                      <strong>Município</strong>: {item.projectcity}
                    </div>
                    <div>
                      <strong>Estado</strong>: {item.projectstate}
                    </div>
                    <div>
                      <strong>Latitude</strong>: {item.projectcoordx.toFixed(4)}
                      <sup>o</sup>
                    </div>
                    <div>
                      <strong>Longitude</strong>:{" "}
                      {item.projectcoordy.toFixed(4)}
                      <sup>o</sup>
                    </div>
                    {/* <div>
                      <br />
                      <small>
                        Após selecionar o projeto, clique fora do balão e aperte{" "}
                        <strong>Enter</strong> para voar até o projeto.
                      </small>
                    </div> */}
                  </Popup>
                  <Tooltip>{item.projectname}</Tooltip>
                  {/* <Tooltip>
                    <div>
                      <p>
                        <strong>Selecione</strong> o Projeto na tabela acima do
                        mapa.
                      </p>
                      <p>
                        Depois clique no mapa e tecle <strong>Enter</strong>
                      </p>
                    </div>
                  </Tooltip> */}
                </Marker>
              </div>
            ))
          ) : (
            <></>
          )}
        </Pane>
        <ZoommToProject />

        <Pane>
          {projectPlots ? (
            projectPlots.map((item) => (
              <div key={item.lat}>
                <Marker
                  icon={myIcon2}
                  zIndex={2}
                  key={item.name}
                  position={[item.lat, item.lng]}
                >
                  <Tooltip>{item.name}</Tooltip>
                  <Popup>
                    <div>
                      <strong>Parcela:</strong> {item.name}
                    </div>
                    <div>
                      <strong>
                        Área (m<sup>2</sup>):
                      </strong>{" "}
                      {item.area}
                    </div>
                    <div>
                      <strong>Latitude:</strong> {item.lat.toFixed(4)}
                      <sup>o</sup>
                    </div>
                    <div>
                      <strong>Longitude:</strong> {item.lng.toFixed(4)}
                      <sup>o</sup>
                    </div>
                  </Popup>
                </Marker>
              </div>
            ))
          ) : (
            <></>
          )}
        </Pane>
        {/* <Pane>
          {boundsLat ? (
            <Marker
              icon={myIcon3}
              zIndex={2}
              key={boundsLat}
              position={[boundsLat, boundsLng]}
            >
              <Tooltip>
                <div>
                  <p>Após selecionar o projeto</p>
                  <br />
                  <p>
                    <strong>Clique</strong> no Mapa e <strong>Enter</strong>
                  </p>
                </div>
              </Tooltip>
            </Marker>
          ) : (
            <></>
          )}
        </Pane> */}
      </MapContainer>
    </Container>
  );
}

Leaflet.defaultProps = {
  boundsLat: -16.0612,
  boundsLng: -57.6895,
  boundsId: 1,
};

Leaflet.propTypes = {
  boundsId: PropTypes.number,
  boundsLat: PropTypes.number,
  boundsLng: PropTypes.number,
  height: PropTypes.string.isRequired,
};

export default Leaflet;
