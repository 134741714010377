import React from "react";

import { Route } from "react-router-dom";

import AnalyticsNavigator from "~/components/AnalyticsNavigator";
import CubTrees from "~/pages/Analytics/CubTrees";
import PlotAnalysis from "~/pages/Analytics/PlotAnalysis";
import InventoryAnalysis from "~/pages/Analytics/InventoryAnalysis";

import { Container } from "./styles";

function Analytics() {
  return (
    <>
      <Container>
        <AnalyticsNavigator />
        <Route path="/analytics/cubtrees" isPrivate component={CubTrees} />
        <Route
          path="/analytics/plotanalysis"
          isPrivate
          component={PlotAnalysis}
        />
        <Route
          path="/analytics/inventoryanalysis"
          isPrivate
          component={InventoryAnalysis}
        />
      </Container>
    </>
  );
}

export default Analytics;
