import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import history from '~/services/history';
import { Container } from './styles';

// import Route from '~/routes/Route';
import serviceProject from '~/pages/Newservice/ProjectSelect'; // Isso é uma função
import services from '~/pages/Newservice/Myservices';

import ServicesNavigator from '~/components/ServicesNavigator';

function Newservice() {
  useEffect(() => {
    history.push('/services/list');
  }, []);

  return (
    <>
      <Container>
        <ServicesNavigator />
        <Route path="/services/list" isPrivate component={services} />
        <Route
          path="/services/newservice"
          isPrivate
          component={serviceProject}
        />
      </Container>
    </>
  );
}

export default Newservice;
