/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
// import { parseISO, format } from 'date-fns';
// import pt from 'date-fns/locale/pt';
// import api from '~/services/api';

import { Container } from "./styles";

function CubViewer() {
  // const [sections, setSections] = useState([]);
  const [dataToTable, setDataToTable] = useState([]);

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    selectableRows: "none",
    fixedSelectColumn: false,
    fixedHeader: true,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    pagination: true,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro para o projeto.",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "TUDO",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
      selectedRows: {
        text: "Linhas selecionadas",
        delete: "Deletar",
        deleteAria: "Deletar linhas selecionadas",
      },
    },
  };

  const columns = [
    {
      label: "Talhão",
      name: "Talhão",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Parcela",
      name: "Parcela",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Árvore",
      name: "Árvore",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "DAP (cm)",
      name: "DAP (cm)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Altura total(m)",
      name: "Altura total(m)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Altura comercial (m)",
      name: "Altura comercial (m)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Seção",
      name: "Seção",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "hi (m)",
      name: "hi (m)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "di com casca (cm)",
      name: "di (cm)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "di sem casca (cm)",
      name: "di (cm)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "di do cerne(cm)",
      name: "di cerne(cm)",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  function handleData(data) {
    const dataTable = [];

    // É melhor estruturar a consulta via backend como estava para árvores (Tree)

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      for (let j = 0; j < element.stands.length; j++) {
        const subelement = element.stands[j];

        for (let k = 0; k < subelement.plots.length; k++) {
          const subelement2 = subelement.plots[k];

          for (let s = 0; s < subelement2.inventories.length; s++) {
            const subelement3 = subelement2.inventories[s];

            for (let z = 0; z < subelement3.trees.length; z++) {
              const subelement4 = subelement3.trees[z];

              for (let t = 0; t < subelement4.sections.length; t++) {
                const subelement5 = subelement4.sections[t];

                const dataItem = {
                  talhao: subelement.standname,
                  parcela: subelement2.plotname,
                  arvore: subelement4.tree,
                  dap: subelement4.treedap,
                  ht: subelement4.treeht,
                  hc: subelement4.treehc,
                  section: subelement5.section,
                  hi: subelement5.sectionhi,
                  dicc: subelement5.sectiondicc,
                  disc: subelement5.sectiondisc,
                  dice: subelement5.sectiondice,
                };
                dataTable.push(dataItem);
              }
            }
          }
        }
      }
    }

    setDataToTable(dataTable);
  }

  useEffect(() => {
    async function loadTreesFromSession() {
      const dataSession = JSON.parse(sessionStorage.getItem("projectData"));

      if (dataSession !== null) {
        handleData(dataSession);
      }
    }

    loadTreesFromSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        <MUIDataTable
          title="Seções Amostradas"
          data={dataToTable.map((d) => {
            return [
              d.talhao,
              d.parcela,
              d.arvore,
              d.dap.toFixed(1),
              d.ht ? d.ht.toFixed(1) : null,
              d.hc ? d.hc.toFixed(1) : null,
              d.section,
              d.hi ? d.hi.toFixed(2) : null,
              d.dicc ? d.dicc.toFixed(1) : null,
              d.disc ? d.disc.toFixed(1) : null,
              d.dice ? d.dice.toFixed(1) : null,
            ];
          })}
          columns={columns}
          options={options}
        />
      </Container>
    </>
  );
}

export default CubViewer;
