import styled from "styled-components";
// import { darken } from "polished";

export const Content = styled.div`
  @media only screen and (max-width: 768px) {
    display: block;
  }
  div {
    width: 100%;
    height: 100%;
  }
  display: flex;
  margin: 0 auto;
  height: auto;
  margin: 0 auto;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const Container = styled.div`
  @media only screen and (max-width: 768px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    form {
      display: block;
    }
    nav {
      a {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }

  h1 {
    color: blue;
    padding-top: 20px;
    padding-bottom: 10px;
  }
  width: auto;
  height: auto;
  padding-bottom: 200px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* flex-wrap: nowrap; */
  flex-direction: column;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;

  .hide {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    padding: 5px;
    margin: 5px auto;
    align-items: center;
    align-content: center;
    flex-direction: row;

    div {
      h4 {
        color: blue;
        height: 100%;
        align-items: center;
        align-content: center;
      }
    }
  }

  .myDiv {
    padding-top: 10px;
  }

  .myDiv:hover + .hide {
    visibility: visible;
    position: relative;
    background: #ddd;
    border-radius: 5px;
  }

  div.plot {
    display: flex;
    max-width: 90vw;
    max-height: 350px;
    padding: 0;
    align-items: center;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    padding-left: auto;
    padding-right: auto;
  }

  div.wrap {
    width: 80vw;
    display: flex;
    flex-wrap: wrap;
    padding-top: 20px;
  }
  div.graphics {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding-top: 20px 0;

    @media only screen and (max-width: 768px) {
      width: 100%;
    }
  }

  div.graphic {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: auto;
    padding-top: 20px;
  }
`;
