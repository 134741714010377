import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px;

  div {
    background-color: #009000;
    padding: 5px;
    width: 200px;
    border-radius: 5px;

    h1 {
      color: #000;
      font-size: 24px;
    }

    h2 {
      color: #fff;
      font-size: 32px;
    }

    transition-duration: 1s;
    /* transition-delay: 2s; */
    &:hover {
      background-color: #00a000;
      transform: translateX(10px);
      transition: all 0.3s;
    }
  }
`;
