import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { Container } from './styles';

import Step1 from './ServiceStep1';
import Step2 from './ServiceStep2';
import Step3 from './ServiceStep3';

/** Este component é um nest component
 * O State está no component pai que passará para ele
 */

function ProjectSelect() {
  const [stepp, setStepp] = useState(0);

  const steps = [
    'Selecione um projeto e as parcelas a serem medidas',
    'Selecione um responsável para o inventário',
    'Crie a ordem de serviço',
  ];

  // const [newArray, setNewArray] = useState([]);

  function handleAddStep() {
    if (stepp < 2) {
      setStepp(stepp + 1);
    }
  }

  function handleSubStep() {
    if (stepp > 0) {
      setStepp(stepp - 1);
    }
  }

  function componentSteppeRender(step) {
    switch (step) {
      case 0:
        return <Step1 handleAdd={handleAddStep} />;

      case 1:
        return <Step2 handleAdd={handleAddStep} />;

      case 2:
        return <Step3 />;

      default:
        return <div>Default</div>;
    }
  }

  return (
    <>
      <Container>
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={stepp} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>

        {/* <div> */}
        <button className="stepper" onClick={handleSubStep} type="submit">
          Voltar
        </button>
        {/* <button className="stepper" onClick={handleAddStep} type="submit">
            Próximo
          </button> */}
        {/* </div> */}

        {componentSteppeRender(stepp)}
      </Container>
    </>
  );
}

ProjectSelect.prototype = {
  handleAdd: PropTypes.func,
};

export default ProjectSelect;
