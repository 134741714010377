import React from "react";
import { Route } from "react-router-dom";
// import history from '~/services/history';

import DataViewer from "~/pages/MyData/DataViewer";
import DataViewer2 from "~/pages/MyData/DataViewer2";
import CubViewer from "~/pages/MyData/CubViewer";
import DataNavigator from "~/components/DataNavigator";

import { Container } from "./styles";

function MyData() {
  return (
    <>
      <Container>
        <DataNavigator />
        <Route path="/mydata/dataviewer" isPrivate component={DataViewer} />
        <Route path="/mydata/dataviewer2" isPrivate component={DataViewer2} />
        <Route path="/mydata/cubviewer" isPrivate component={CubViewer} />
      </Container>
    </>
  );
}

export default MyData;
