import React, { useState, useRef, useEffect } from "react";
import { useField } from "@rocketseat/unform";
import { useSelector } from "react-redux";
import api from "~/services/api";

import Resizer from "react-image-file-resizer";

import { Container } from "./styles";

export default function AvatarInput() {
  const { defaultValue, registerField } = useField("avatar");
  const profile = useSelector((state) => state.manager.profile);

  const [file, setFile] = useState(defaultValue && defaultValue.id);
  const [preview, setPreview] = useState(defaultValue && defaultValue.url);

  const ref = useRef();

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        200,
        200,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
          console.log(uri);
        },
        "file"
      );
    });

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: "avatar_id",
        user: profile.uuid,
        ref: ref.current,
        path: "dataset.file",
      });
    }
  }, [profile.uuid, ref, registerField]);

  async function handleChange(e) {
    const data = new FormData();

    const file = e.target.files[0];

    // Dá pra fazer resize aqui no front
    const image = await resizeFile(file);

    data.append("file", image);

    data.append("user", profile.uuid);

    const response = await api.post("files", data);

    const { id, url } = response.data;

    setFile(id);
    setPreview(url);
  }

  return (
    <Container>
      <label htmlFor="avatar">
        <img
          src={
            preview ||
            "https://i.pinimg.com/originals/3f/94/70/3f9470b34a8e3f526dbdb022f9f19cf7.jpg"
          }
          alt=""
        />

        <input
          type="file"
          id="avatar"
          accept="image/*"
          data-file={file}
          onChange={handleChange}
          ref={ref}
        />
      </label>
    </Container>
  );
}
