import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  flex-direction: row;
  align-items: stretch;
  align-content: space-around;
  flex-wrap: wrap;
  justify-content: space-around;
  overflow: hidden;
`;
