import styled from "styled-components";
// import { darken } from 'polished';

export const Container = styled.div`
  /* display: flex;
  width: 100%; */

  margin-left: auto;
  margin-right: auto;
  justify-content: center;
  align-items: center;

  img {
    width: 300px;
    height: auto;
  }

  div {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`;
