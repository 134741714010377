import React, { useState, useEffect } from "react";
import { Form, Select, Input } from "@rocketseat/unform";
import * as Yup from "yup";
import { toast } from "react-toastify";
import api from "~/services/api";
import history from "~/services/history";

import { Container, Content } from "./styles";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

const schema = Yup.object().shape({
  projectname: Yup.string().required("O nome do projeto é obrigatório"),
  projectowner: Yup.string().required("O nome do proprietário é obrigatório"),
  projectcity: Yup.string().required("O nome do município é obrigatório"),
  projectstate: Yup.string().required(
    "O nome da Unidade Federativa  é obrigatória"
  ),
  projectcoordx: Yup.number()
    .required("Insira uma coordenada válida")
    .min(-90, "Latitude deve estar entre +-90º")
    .max(90, "Latitude deve estar entre +-90º")
    .typeError("Insira um valor numérico | Use . como separador decimal"),
  // .matches(),
  projectcoordy: Yup.number()
    .required("Insira uma coordenada válida")
    .min(-180, "Longitude deve estar entre +-180º")
    .max(180, "Longitude deve estar entre +-180º")
    .typeError("Insira um valor numérico | Use . como separador decimal"),
});

const statesList = [
  { id: "AC", title: "AC" },
  { id: "AL", title: "AL" },
  { id: "AM", title: "AM" },
  { id: "AP", title: "AP" },
  { id: "BA", title: "BA" },
  { id: "CE", title: "CE" },
  { id: "DF", title: "DF" },
  { id: "ES", title: "ES" },
  { id: "MA", title: "MA" },
  { id: "PI", title: "PI" },
  { id: "PB", title: "PB" },
  { id: "MT", title: "MT" },
  { id: "MS", title: "MS" },
  { id: "GO", title: "GO" },
  { id: "PA", title: "PA" },
  { id: "SE", title: "SE" },
  { id: "MG", title: "MG" },
  { id: "PR", title: "PR" },
  { id: "RJ", title: "RJ" },
  { id: "RN", title: "RN" },
  { id: "RO", title: "RO" },
  { id: "RR", title: "RR" },
  { id: "RS", title: "RS" },
  { id: "SC", title: "SC" },
  { id: "SP", title: "SP" },
  { id: "TO", title: "TO" },
  { id: "PE", title: "PE" },
];

function ProjectUpdate() {
  const initialData2 = JSON.parse(sessionStorage.getItem("projectUpdateData"));
  const [projects, setMyProjects] = useState([]);
  const [dataOfProjects, setDataOfProjects] = useState([]);
  const [search, setSearch] = useState("");

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // useEffect(() => {

  // }, [])

  useEffect(() => {
    async function loadMyProjects() {
      const response = await api.get("/myprojects");

      const data = response.data.map((project) => ({
        ...project,
      }));

      setDataOfProjects(data);

      const projectOptions = data.map((e) => {
        return {
          id: e.id,
          title: e.projectname,
        };
      });

      setMyProjects(projectOptions);
    }
    loadMyProjects();
  }, []);

  const projectsFiltered =
    search.length > 0
      ? projects.filter((p) => p.title.includes(search))
      : projects;

  async function handleUpdateProject(project) {
    const Selectedproject = JSON.parse(
      sessionStorage.getItem("projectUpdateData")
    );
    project.id = Selectedproject.id;
    try {
      await api.put("/myprojects", project);
      toast.success("Os dados do projeto foram atualizados.");
      sessionStorage.removeItem("projectUpdateData");
      history.push("/projectsManager");
    } catch (err) {
      toast.error("Não foi possível atualizar os dados do projeto.");
    }
  }

  async function handleDeleteProject() {
    setOpen(false);
    const Selectedproject = JSON.parse(
      sessionStorage.getItem("projectUpdateData")
    );

    if (Selectedproject) {
      try {
        const response = await api.delete(`/myprojects/${Selectedproject.id}`);
        console.log(response);

        toast.success("Os dados do projeto foram excluídos com sucesso.");
        sessionStorage.removeItem("projectUpdateData");
        history.push("/projectsManager");
      } catch (err) {
        // toast.error(`${JSON.stringify(err.response.data)}`);
        toast.error(
          `O projeto possui inventários realizados e não pode ser deletado.`
        );
      }
    } else {
      toast.error("Nenhum projeto selecionado!");
    }
  }

  async function handleSelectProject(project) {
    // setData(project);
    // console.log(project);
    // props.setProjectData(project.name, project.projectname);
    if (project) {
      const Theproject = dataOfProjects.find(
        (e) => e.id === parseFloat(project.id)
      );

      sessionStorage.setItem("projectUpdateData", JSON.stringify(Theproject));
    }
    toast.success("Projeto carregado para atualização.");
    history.push("/projectsManager");
  }

  return (
    <>
      <Container>
        <Content>
          <Form onSubmit={handleSelectProject}>
            <div className="Column">
              <div>
                <Input
                  name="search"
                  type="buscar"
                  placeholder="Filtrar"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                />
              </div>
              <div>
                <Select
                  className="project"
                  required
                  name="id"
                  options={projectsFiltered}
                  placeholder="Projeto"
                />
              </div>
              <div>
                <button type="submit">Carregar projeto</button>
              </div>
            </div>
          </Form>

          <Form
            onSubmit={handleUpdateProject}
            initialData={initialData2}
            schema={schema}
          >
            <div className="Column">
              <div className="Row">
                <div>
                  <Input
                    name="projectname"
                    type="name"
                    placeholder="Nome do Projeto"
                  />
                </div>
                <div>
                  <Input
                    name="projectowner"
                    type="name"
                    placeholder="Proprietário"
                  />
                </div>
              </div>
              <div className="Row">
                <div>
                  <Input
                    name="projectcity"
                    type="name"
                    placeholder="Município"
                  />
                </div>
                <div>
                  {/* <Input name="projectstate" type="name" placeholder="Estado" /> */}
                  <Select
                    required
                    name="projectstate"
                    options={statesList}
                    placeholder="Estado (UF)"
                    style={{ fontSize: "14px", color: "#444" }}
                  />
                </div>
              </div>
              <div className="Row">
                <div>
                  <Input
                    name="projectcoordx"
                    type="name"
                    placeholder="Latitude º"
                  />
                </div>
                <div>
                  <Input
                    name="projectcoordy"
                    type="name"
                    placeholder="Longitude º"
                  />
                </div>
              </div>
              <button type="submit">
                <h3>Atualizar o projeto</h3>
              </button>
            </div>
          </Form>
          <button
            className="delete"
            type="submit"
            onClick={() => handleClickOpen()}
          >
            <h3>Apagar o projeto</h3>
          </button>
        </Content>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: "white",
              boxShadow: "inherit",
              // color: 'blue',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Têm certeza que deseja excluir o projeto?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              As informações de cadastro da fazenda, talhões e parcelas serão
              perdidos.
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Um projeto só poderá ser deletado se não houverem inventários
              realizados para o mesmo.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleDeleteProject}>Deletar</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default ProjectUpdate;
