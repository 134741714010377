/* eslint-disable react/prop-types */
import React, { forwardRef, useEffect, useState } from "react";

import { Container, Content } from "./styles";

import axios from "axios";
import api from "~/services/api";

import Loading from "~/components/Loading";

import { FcInfo } from "react-icons/fc";

import { parseISO, format } from "date-fns";
import pt from "date-fns/locale/pt";

import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  Label,
  ScatterChart,
  // Cell,
  // ZAxis,
  Legend,
  Scatter,
} from "recharts";

import { toast } from "react-toastify";

// eslint-disable-next-line no-unused-vars
const Step1 = forwardRef((props, ref) => {
  let Data = JSON.parse(sessionStorage.getItem("DataToPlot"));
  let Data2 = JSON.parse(sessionStorage.getItem("DataToPlot2"));
  let Data3 = JSON.parse(sessionStorage.getItem("DataToPlot3"));

  const [hasData, setHasData] = useState(Data ? true : false);

  const [DataToPlot, setDataToPlot] = useState(Data ? Data : []);
  const [DataToPlot2, setDataToPlot2] = useState(Data2 ? Data2 : []);
  const [DataToPlot3, setDataToPlot3] = useState(Data3 ? Data3 : []);
  const [Consisted, setConsisted] = useState(
    JSON.parse(sessionStorage.getItem("Consisted"))
  );
  const [loading, setLoading] = useState(true);

  const COLORS = [
    "#0000FF",
    "#FF0000",
    "#008000",
    "#8a2be2",
    "#800000",
    "#00FF00",
    "#FF8042",
    "#003366",
    "#FF00cc",
    "#800080",
    "#57A0D2",
    "#00C49F",
    "#065535",
    "#4682B4",
    "#FFD700",
    "#A07000",
    "#CD7F32",
    "#AAAAAA",
    "#BEC2CB",
    "#000000",
  ];

  function transformData(originalData) {
    const result = {};
    originalData.forEach((obj) => {
      if (!result[obj.name]) {
        result[obj.name] = { plot: obj.name, data: [] };
      }
      result[obj.name].data.push(obj);
    });
    setDataToPlot(Object.values(result));
    sessionStorage.setItem("DataToPlot", JSON.stringify(Object.values(result)));
  }

  function transformData2(originalData) {
    const result = {};
    originalData.forEach((obj) => {
      if (!result[obj.date]) {
        result[obj.date] = { plot: obj.date, data: [] };
      }
      result[obj.date].data.push(obj);
    });
    setDataToPlot2(Object.values(result));
    sessionStorage.setItem(
      "DataToPlot2",
      JSON.stringify(Object.values(result))
    );
  }

  function transformData3(originalData) {
    const result = {};
    originalData.forEach((obj) => {
      if (!result[obj.date]) {
        result[obj.date] = { plot: obj.date, data: [] };
      }
      result[obj.date].data.push(obj);
    });
    setDataToPlot3(Object.values(result));
    sessionStorage.setItem(
      "DataToPlot3",
      JSON.stringify(Object.values(result))
    );
  }

  async function handleData(data) {
    const dataTrees = [];

    const dataSections = [];

    // É melhor estruturar a consulta via backend como estava para árvores (Tree)

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      for (let j = 0; j < element.stands.length; j++) {
        const subelement = element.stands[j];

        for (let k = 0; k < subelement.plots.length; k++) {
          const subelement2 = subelement.plots[k];

          for (let s = 0; s < subelement2.inventories.length; s++) {
            const subelement3 = subelement2.inventories[s];

            for (let z = 0; z < subelement3.trees.length; z++) {
              const subelement4 = subelement3.trees[z];

              const dataItem = {
                talhao: subelement.standname,
                parc: subelement2.plotname,
                arv: subelement4.tree,
                date: format(parseISO(subelement3.monthyear), "MM/yyyy", {
                  locale: pt,
                }),
                dap: JSON.parse(subelement4.treedap.toFixed(1)),
                ht: subelement4.treeht
                  ? JSON.parse(subelement4.treeht.toFixed(1))
                  : null,
              };

              dataTrees.push(dataItem);

              for (let t = 0; t < subelement4.sections.length; t++) {
                const subelement5 = subelement4.sections[t];

                const DataSubitem = {
                  talhao: subelement.standname,
                  parc: subelement2.plotname,
                  dap: JSON.parse(subelement4.treedap.toFixed(1)),
                  section: subelement5.section ? subelement5.section : 0,
                  sectionhi: subelement5.sectionhi
                    ? JSON.parse(subelement5.sectionhi.toFixed(1))
                    : 0,
                  sectiondicc: subelement5.sectiondicc
                    ? JSON.parse(subelement5.sectiondicc.toFixed(1))
                    : 0,
                  sectiondisc: subelement5.sectiondisc
                    ? JSON.parse(subelement5.sectiondisc.toFixed(1))
                    : 0,
                  arv: subelement4.tree,
                  ht: subelement4.treeht
                    ? JSON.parse(subelement4.treeht.toFixed(1))
                    : 0,
                  hc: subelement4.treehc
                    ? JSON.parse(subelement4.treehc.toFixed(1))
                    : 0,
                  date: format(parseISO(subelement3.monthyear), "dd/MM/yyyy", {
                    locale: pt,
                  }),
                };
                dataSections.push(DataSubitem);
              }
            }
          }
        }
      }
    }

    sessionStorage.setItem("dataTrees", JSON.stringify(dataTrees));
    sessionStorage.setItem("dataSections", JSON.stringify(dataSections));

    transformData2(dataTrees);
    transformData3(dataSections);

    const checkedData = JSON.parse(sessionStorage.getItem("DataToPlot"));

    if (!checkedData) {
      const response = await api.post("/globalhistogram", dataTrees);
      transformData(response.data);
      setLoading(false);
    } else {
      setDataToPlot(checkedData);
      setLoading(false);
    }

    // setDataToPlot(response.data);
  }

  useEffect(() => {
    async function loadProjectData() {
      const dataSession = JSON.parse(sessionStorage.getItem("projectData"));

      if (dataSession !== null) {
        handleData(dataSession);
        setConsisted(false);
        setHasData(true);
      }
    }

    if (DataToPlot.length === 0) {
      loadProjectData();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Esta função é usada pelo pai
  function handleSaveData(project) {
    // setData(project);
    // console.log(project);
    // props.setProjectData(project.name, project.projectname);
    if (project) {
      if (Consisted) {
        props.handleAdd();
      } else {
        toast.info("Dados do projeto sem análise de consistência.");
      }
    }
  }

  async function handleValidate() {
    if (!Consisted) {
      setLoading(true);
      const treesData = JSON.parse(sessionStorage.getItem("dataTrees"));
      const sectionsData = JSON.parse(sessionStorage.getItem("dataSections"));

      async function paralaleRequest() {
        const Allresponses = [];
        await axios
          .all([
            axios.post(
              "https://www.backfortrees.datagro.tec.br/consisthxd",
              treesData
            ),
            axios.post(
              "https://www.backfortrees.datagro.tec.br/consistdap",
              treesData
            ),
            axios.post(
              "https://www.backfortrees.datagro.tec.br/consistsections",
              sectionsData
            ),
          ])
          .then((responses) => {
            responses.forEach((resp, index) => {
              Allresponses[index] = resp.data;
            });
          });
        return Allresponses;
      }

      const Responses = await paralaleRequest();

      const response3 = await api.post("/globalhistogram", Responses[1]);

      transformData(Responses[0]);
      transformData(response3.data);
      transformData2(Responses[0]);
      transformData3(Responses[2]);

      sessionStorage.setItem("Consisted", true);
      setConsisted(true);
      setLoading(false);
      toast.success("Dados do projeto consistidos.");
    } else {
      toast.info("Dados do projeto já foram consistidos.");
    }
  }

  return (
    <>
      <Content>
        <Loading loading={loading} />
      </Content>

      {hasData ? (
        <>
          <Container>
            {!Consisted ? (
              <>
                <div className="myDiv">
                  <button
                    className="azul"
                    type="submit"
                    onClick={handleValidate}
                  >
                    <h3>Consistir os dados</h3>
                  </button>
                </div>
                <div className="hide">
                  <FcInfo size={25} />
                  <h4>
                    Avalia os dados do projeto usando testes que detectam, e
                    eliminam, valores discrepantes.
                  </h4>
                </div>
              </>
            ) : (
              <></>
            )}

            <h1>
              Visualização dos dados {Consisted ? "consistidos" : "brutos"}
            </h1>
            <h2>Distribuição Diamétrica por Inventário</h2>
            <div className="wrap">
              {DataToPlot.map((e) => (
                <div key={e.plot} className="graphics">
                  <h4>Dados de {e.plot}</h4>
                  <ResponsiveContainer width="100%" height={350}>
                    <BarChart
                      key={e.plot}
                      data={e.data}
                      margin={{
                        top: 0,
                        right: 0,
                        left: 20,
                        bottom: 60,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="classes">
                        <Label
                          value={"DAP (cm)"}
                          position="bottom"
                          style={{
                            textAnchor: "middle",
                            fontSize: "130%",
                            fill: "black",
                          }}
                        />
                      </XAxis>
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "130%",
                            fill: "black",
                          }}
                          position="left"
                          angle={270}
                          value={"Número de Árvores (n)"}
                        />
                      </YAxis>
                      <Tooltip />
                      <Bar dataKey="count" fill="#0000FF" />
                    </BarChart>
                  </ResponsiveContainer>
                </div>
              ))}
            </div>

            <div className="graphic">
              <h2>Relação Hipsométrica</h2>
              <ResponsiveContainer width="100%" height={400}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid />
                  <Tooltip cursor={{ strokeDasharray: "2 2" }} />

                  <XAxis
                    type="number"
                    dataKey="dap"
                    name="DAP"
                    unit="cm"
                    position="end"
                    domain={["auto", "auto"]}
                    interval={"preserveStartEnd"}
                    tickCount={8}
                    allowDecimals={true}
                  >
                    <Label
                      value={"Diâmetro à Altura do Peito (cm)"}
                      position="bottom"
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                    />
                  </XAxis>
                  <YAxis
                    type="number"
                    dataKey="ht"
                    name="HT"
                    unit="m"
                    domain={["auto", "auto"]}
                  >
                    <Label
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                      position="left"
                      angle={270}
                      value={"Altura Total (m)"}
                    />
                  </YAxis>

                  {/* <Tooltip cursor={{ strokeDasharray: "3 3" }} /> */}

                  <Legend verticalAlign="top" height={36} />

                  {DataToPlot2.map((item, index) => (
                    <>
                      <Scatter
                        key={`cell-${index}`}
                        name={item.plot}
                        data={item.data}
                        // fill={`#${Math.floor(Math.random() * 999999)}`}
                        // fill={COLORS[Math.floor(Math.random() * COLORS.length)]}
                        fill={COLORS[index]}
                      />
                    </>
                  ))}
                </ScatterChart>
              </ResponsiveContainer>
            </div>

            <div className="graphic">
              <h2>Dados de Afilamento</h2>
              <ResponsiveContainer width="100%" height={400}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid />
                  <Tooltip cursor={{ strokeDasharray: "2 2" }} />

                  <XAxis
                    type="number"
                    dataKey="sectionhi"
                    name="hi"
                    unit="m"
                    position="end"
                    domain={["auto", "auto"]}
                    interval={"preserveStartEnd"}
                    tickCount={8}
                    allowDecimals={true}
                  >
                    <Label
                      value={"Altura da seção (m)"}
                      position="bottom"
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                    />
                  </XAxis>
                  <YAxis
                    type="number"
                    dataKey="sectiondicc"
                    name="di"
                    unit="cm"
                    domain={["auto", "auto"]}
                  >
                    <Label
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                      position="left"
                      angle={270}
                      value={"Diâmetro da seção (cm)"}
                    />
                  </YAxis>

                  {/* <Tooltip cursor={{ strokeDasharray: "3 3" }} /> */}

                  <Legend verticalAlign="top" height={36} />

                  {DataToPlot3.map((item, index) => (
                    <>
                      <Scatter
                        key={`cell-${index}`}
                        name={item.plot}
                        data={item.data}
                        // fill={`#${Math.floor(Math.random() * 999999)}`}
                        // fill={COLORS[Math.floor(Math.random() * COLORS.length)]}
                        fill={COLORS[index]}
                      />
                    </>
                  ))}
                </ScatterChart>
              </ResponsiveContainer>
            </div>

            <div className="myDiv">
              <button className="verde" type="submit" onClick={handleSaveData}>
                <h3>Avançar para 2ª etapa</h3>
              </button>
            </div>
            <div className="hide">
              <FcInfo size={25} />
              <h4>
                Avança para próxima etapa de processamento com os dados
                consistidos.
              </h4>
            </div>
          </Container>
        </>
      ) : (
        <>
          <div>
            <br />
            <p>Nenhum projeto carregado</p>
          </div>
        </>
      )}
    </>
  );
});

export default Step1;
