import styled from "styled-components";
import { darken } from "polished";

export const Wrapper = styled.div`
  height: 100%;
  background: linear-gradient(135deg, #ffffff, #007000);
  display: flex;
  align-items: flex-start;
  justify-content: center;

  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Content = styled.div`
  width: auto;
  text-align: center;

  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    input {
      background: rgba(0, 0, 0, 0.07);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 15px;
      color: #000;
      margin: 0 0 10px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
    span {
      color: #964b00;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: #00b000;
      font-weight: bold;
      color: #000;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.02, "#00d000")};
      }
    }
    a {
      color: #000;
      margin: 5px;
      text-align: right;
      font-size: 12px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
`;
