import styled from 'styled-components';
import { darken } from 'polished';

export const InputDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  max-width: 100vw;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  max-width: auto;
  margin-bottom: 100px;

  div.dois {
    padding-right: 300px;
  }

  div {
    margin-left: auto;
    margin-right: auto;
    /* padding-top: 10px; */
    h6 {
      font-weight: bold;
    }
  }

  table {
    width: auto;
    height: auto;
    text-align: center;

    thead,
    tbody {
      width: 100%;
    }

    th,
    span {
      font-weight: bold;
      font-size: 16px;
    }

    div {
      text-align: center;
    }
  }
  tr,
  th,
  td {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    border: 0px;
    text-align: center;
    vertical-align: center;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: center;
    max-width: auto;
    margin-left: auto;
    margin-right: auto;

    select {
      background: rgba(0, 0, 0, 0.07);
      border: 0;
      border-radius: 4px;
      height: 44px;
      width: 200px;
      color: #000;
      margin: 10px;
      padding: 5px;
      margin-left: auto;
      margin-right: auto;
    }

    button.verde {
      height: 44px;
      width: 250px;
      max-width: 100vw;
      background: rgba(0, 112, 0, 0.7);
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      margin-top: 30px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, 'rgba(0, 212, 0, 1)')};
      }
    }
    button.azul {
      margin-left: auto;
      margin-right: auto;
      height: 44px;
      width: 250px;
      margin-top: 30px;
      background: rgba(0, 0, 112, 0.7);
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, 'rgba(0, 0, 212, 1)')};
      }
    }
  }

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 112, 0, 1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${darken(0.03, 'rgba(0, 112, 0, 0.7)')};
  }
`;
