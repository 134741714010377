import React, { useEffect, useState } from "react";
import { Form, Input } from "@rocketseat/unform";
import { useSelector } from "react-redux";
import * as Yup from "yup";
import { toast } from "react-toastify";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { MdDelete } from "react-icons/md";
import { Container } from "./styles";

import Loading from "~/components/Loading";
import api from "~/services/api";

const schema = Yup.object().shape({
  name: Yup.string().required("O nome é obrigatório"),
  email: Yup.string()
    .email("Insira um e-mail válido")
    .required("O e-mail é obrigatório"),
  password: Yup.string().required("A senha é obrigatória"),
});

function CollectorRegister() {
  const [collectors, setCollectors] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [emailToDelete, setEmailToDelete] = useState();
  const [reload, setReload] = useState(false);
  const [loading, setLoading] = useState(false);

  const profile = useSelector((state) => state.manager.profile);

  const handleClickOpen = (email) => {
    setOpen(true);
    setEmailToDelete(email);
  };

  const handleClose = () => {
    setOpen(false);
  };

  function handleSubmit(data, { resetForm }) {
    setLoading(true);
    async function postCollector() {
      if (profile.collectors_limit <= collectors.length) {
        setLoading(false);
        toast.warn(`Limite de coletores atingido para esta conta.`);
      } else {
        try {
          await api.post("/collectors", data);

          setCollectors([data, ...collectors]);

          setLoading(false);
          setReload(true);
          toast.success(`Coletor cadastrado com sucesso!`);
        } catch (err) {
          setLoading(false);
          toast.error(
            `Este usuário(e-mail) já está cadastrado para outro Administrador`
          );
        }
      }
    }

    postCollector();
    resetForm();
  }

  useEffect(() => {
    setLoading(true);
    async function loadCollectors() {
      const alreadyLoaded = JSON.parse(
        sessionStorage.getItem("CollectorsInfo")
      );

      const refreshedTime =
        JSON.parse(sessionStorage.getItem("CollectorRefreshTime")) ||
        new Date();
      let now = new Date().valueOf();

      if (alreadyLoaded && now <= refreshedTime && !reload) {
        setCollectors(alreadyLoaded);
        setLoading(false);
        setReload(false);
      } else {
        const response = await api.get("/collectors");

        const data = response.data.map((collector) => ({
          ...collector,
        }));

        const loadedTime = new Date();
        const refresh = loadedTime.setTime(
          loadedTime.valueOf() + 1000 * 60 * 20
        );
        sessionStorage.setItem("CollectorRefreshTime", refresh);
        sessionStorage.setItem("CollectorsInfo", JSON.stringify(data));

        setCollectors(data);
        setLoading(false);
      }
    }
    loadCollectors();
  }, [reload]);

  // useEffect(() => {
  //   setLoading(true);
  //   async function loadCollectors() {
  //     const response = await api.get("/collectors");

  //     const data = response.data.map((collector) => ({
  //       ...collector,
  //     }));

  //     setCollectors(data);
  //     setLoading(false);
  //   }
  //   loadCollectors();
  // }, [reload]);

  async function handleDelete() {
    try {
      // Este post é só pra pegar o ID a ser deletado
      const response = await api.post("/collector", {
        email: emailToDelete,
      });

      const collectorId = response.data.uuid;

      await api.delete(`/collector/${collectorId}`);

      setOpen(false);

      setReload(true);

      toast.success("Usuário deletado com sucesso!");
    } catch (err) {
      toast.error(`Fudeu ${err}`);
    }
    // async function deleteCollector() {
    //   console.log(emailToDelete);

    //   const body = {
    //     email: 'davi@gmail.com',
    //   };

    //   console.log(body.email);

    // }
    // deleteCollector();
  }

  return (
    <>
      <Loading loading={loading} />
      <Container>
        <br />
        <h2>Cadastrar novo coletor de dados:</h2>
        <Form schema={schema} onSubmit={handleSubmit}>
          <div>
            <Input name="name" type="name" placeholder="Nome do Coletor" />
          </div>
          <div>
            <Input name="email" type="email" placeholder="Email do Coletor" />
          </div>
          <div>
            <Input
              name="password"
              type="password"
              placeholder="Senha do coletor"
            />
          </div>

          <button type="submit">Cadastrar</button>
        </Form>
        <div>
          <h2>Lista de coletores de dados</h2>
          <table>
            <thead>
              <tr>
                <th />
                <th>Coletor</th>
                <th>Email</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {collectors.map((collector) => (
                <tr key={collector.email}>
                  <td>
                    <img
                      src={
                        collector.avatar
                          ? collector.avatar.url
                          : "https://i0.wp.com/cms.ironk12.org/wp-content/uploads/2020/02/no-person-profile-pic.png?ssl=1"
                      }
                      alt="None"
                    />
                  </td>
                  <td>{collector.name}</td>
                  <td>{collector.email}</td>
                  <td>
                    <button
                      type="submit"
                      onClick={() => handleClickOpen(collector.email)}
                      className="delete"
                    >
                      <MdDelete size={15} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: "white",
              boxShadow: "inherit",
              // color: 'blue',
            },
          }}
        >
          <DialogTitle id="alert-dialog-title">
            Têm certeza que deseja excluir o prestador?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Este prestador não poderá mais enviar os dados com o smartphone.
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              A listagem de serviços deste prestador também não estará mais
              acessível.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleDelete}>Deletar</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}

export default CollectorRegister;
