/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";

import { Form, Input, Select } from "@rocketseat/unform";

import * as Yup from "yup";

const schema = Yup.object().shape({
  projectname: Yup.string().required("O nome do projeto é obrigatório"),
  projectowner: Yup.string().required("O nome do proprietário é obrigatório"),
  projectcity: Yup.string().required("O nome do município é obrigatório"),
  projectstate: Yup.string().required(
    "O nome da Unidade Federativa  é obrigatória"
  ),
  coordx: Yup.number()
    .required("Insira uma coordenada válida")
    .min(-90, "Latitude deve estar entre +-90º")
    .max(90, "Latitude deve estar entre +-90º")
    .typeError("Insira um valor numérico | Use . como separador decimal"),
  // .matches(),
  coordy: Yup.number()
    .required("Insira uma coordenada válida")
    .min(-180, "Longitude deve estar entre +-180º")
    .max(180, "Longitude deve estar entre +-180º")
    .typeError("Insira um valor numérico | Use . como separador decimal"),
});

const statesList = [
  { id: "AC", title: "AC" },
  { id: "AL", title: "AL" },
  { id: "AM", title: "AM" },
  { id: "AP", title: "AP" },
  { id: "BA", title: "BA" },
  { id: "CE", title: "CE" },
  { id: "DF", title: "DF" },
  { id: "ES", title: "ES" },
  { id: "MA", title: "MA" },
  { id: "PI", title: "PI" },
  { id: "PB", title: "PB" },
  { id: "MT", title: "MT" },
  { id: "MS", title: "MS" },
  { id: "GO", title: "GO" },
  { id: "PA", title: "PA" },
  { id: "SE", title: "SE" },
  { id: "MG", title: "MG" },
  { id: "PR", title: "PR" },
  { id: "RJ", title: "RJ" },
  { id: "RN", title: "RN" },
  { id: "RO", title: "RO" },
  { id: "RR", title: "RR" },
  { id: "RS", title: "RS" },
  { id: "SC", title: "SC" },
  { id: "SP", title: "SP" },
  { id: "TO", title: "TO" },
  { id: "PE", title: "PE" },
];

// eslint-disable-next-line no-unused-vars
const Step1 = forwardRef((props, ref) => {
  // const [data, setData] = useState();

  const initialData = JSON.parse(sessionStorage.getItem("projectData"));

  // Também usar dados do pai no filho
  // console.log(props.newdata);
  // console.log(props.projectdata);

  // Aqui consigo usar uma função do pai no filho
  // props.print();

  // Esta função é usada pelo pai
  function handleAddProject(project) {
    // setData(project);
    // console.log(project);
    props.handleAdd();
    // props.setProjectData(project.name, project.projectname);
    if (project) {
      sessionStorage.setItem("projectData", JSON.stringify(project));
    }
  }

  // function handleSubmit() {
  //   handleAddProject(data);
  // }

  // useImperativeHandle(ref, () => ({
  //   projectAdd: () => handleAddProject(data),
  //   newstep: 1,
  // }));

  return (
    <>
      <Form
        onSubmit={handleAddProject}
        initialData={initialData}
        schema={schema}
      >
        <div className="Column">
          <div className="Row">
            <div>
              <Input
                name="projectname"
                type="name"
                placeholder="Nome do Projeto"
              />
            </div>
            <div>
              <Input
                name="projectowner"
                type="name"
                placeholder="Proprietário"
              />
            </div>
          </div>
          <div className="Row">
            <div>
              <Input name="projectcity" type="name" placeholder="Município" />
            </div>
            <div>
              {/* <Input name="projectstate" type="name" placeholder="Estado" /> */}
              <Select
                required
                name="projectstate"
                options={statesList}
                placeholder="Estado (UF)"
                style={{ fontSize: "14px", color: "#444" }}
              />
            </div>
          </div>
          <div className="Row">
            <div>
              <Input name="coordx" type="name" placeholder="Latitude º" />
            </div>
            <div>
              <Input name="coordy" type="name" placeholder="Longitude º" />
            </div>
          </div>
          <div>
            <button className="verde" type="submit">
              <h3>Salvar</h3>
            </button>
          </div>
        </div>
      </Form>
    </>
  );
});

export default Step1;
