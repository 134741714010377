import React from "react";

import { toast } from "react-toastify";
import history from "~/services/history";
import api from "~/services/api";

import { Div } from "./styles";

function ServiceStep3() {
  const projectOnly = JSON.parse(sessionStorage.getItem("projectOnly"));
  const projectSelected = JSON.parse(sessionStorage.getItem("projectSelected"));
  const collectorSelected = JSON.parse(
    sessionStorage.getItem("collectorSelected")
  );
  const selectedPlots = JSON.parse(sessionStorage.getItem("selectedPlots"));

  async function handleRegister() {
    const Service = {
      collectorSelected,
      projectname: projectOnly,
    };

    const UpdateService = {
      collector_email: collectorSelected,
      projectSelected,
      plots: selectedPlots,
    };

    if (selectedPlots.length === 0 || null) {
      toast.error("Nenhum talhão ou parcelas salvos!");
    } else {
      try {
        await api.post("/services", Service);

        // Aqui tenho que fazer o update dos current_collectors em Plot
        await api.put("/services", UpdateService);

        // Tenho que fazer o Update Plot current_collector
        toast.success("Serviço cadastrado com sucesso!");

        sessionStorage.removeItem("projectOnly");
        sessionStorage.removeItem("collectorSelected");
        sessionStorage.removeItem("selectedPlots");
        sessionStorage.removeItem("ServicesRefreshTime");
        sessionStorage.removeItem("ServicesInfo");
        history.push("/services/list");
      } catch (err) {
        toast.error("Não foi possível cadastrar o serviço!");
      }
    }
  }

  return (
    <>
      <Div>
        <br />
        <h2>Verifique se os dados estão corretos:</h2>

        <table>
          <thead>
            <tr>
              <th>Projeto</th>
              <th>Tallhão</th>
              <th>Parcela</th>
              <th>Reponsável</th>
              <th>Latitude</th>
              <th>Longitude</th>
            </tr>
          </thead>
          <tbody>
            <>
              {selectedPlots ? (
                selectedPlots.map((item) => (
                  <>
                    <tr>
                      <td>{projectOnly}</td>
                      <td>{item.standname}</td>
                      <td>{item.plotname}</td>
                      <td>{collectorSelected}</td>
                      <td>{item.plotcoordx}</td>
                      <td>{item.plotcoordy}</td>
                    </tr>
                  </>
                ))
              ) : (
                <tr>
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                  <td />
                </tr>
              )}
            </>
          </tbody>
        </table>
        <div>
          <br />
          <button className="verde" type="submit" onClick={handleRegister}>
            Cadastrar Ordem de Serviço
          </button>
        </div>
      </Div>
    </>
  );
}

export default ServiceStep3;
