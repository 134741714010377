/* eslint-disable react/prop-types */
import React, { forwardRef, useState } from "react";

import { Container, Content } from "./styles";

import Loading from "~/components/Loading";

import { FcInfo } from "react-icons/fc";

import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  // Bar,
  // BarChart,
  Label,
  ScatterChart,
  // Cell,
  // ZAxis,
  Legend,
  Scatter,
} from "recharts";

import { toast } from "react-toastify";

// eslint-disable-next-line no-unused-vars
const Step3 = forwardRef((props, ref) => {
  // let Data = JSON.parse(sessionStorage.getItem("DataToPlot"));
  // let Data2 = JSON.parse(sessionStorage.getItem("DataToPlot2"));
  let Data3 = JSON.parse(sessionStorage.getItem("DataToPlot3"));

  // const [hasData, setHasData] = useState(Data3 ? true : false);
  const hasData = Data3;
  const DataToPlot3 = Data3;
  const loading = false;

  // const [DataToPlot, setDataToPlot] = useState(Data ? Data : []);
  // const [DataToPlot2, setDataToPlot2] = useState(Data2 ? Data2 : []);

  // const [DataToPlot3, setDataToPlot3] = useState(Data3 ? Data3 : []);

  // const [Consisted, setConsisted] = useState(
  //   JSON.parse(sessionStorage.getItem("Consisted"))
  // );
  const Consisted = useState(JSON.parse(sessionStorage.getItem("Consisted")));

  // const [loading, setLoading] = useState(false);

  const COLORS = [
    "#0000FF",
    "#FF0000",
    "#008000",
    "#8a2be2",
    "#800000",
    "#00FF00",
    "#FF8042",
    "#003366",
    "#FF00cc",
    "#800080",
    "#57A0D2",
    "#00C49F",
    "#065535",
    "#4682B4",
    "#FFD700",
    "#A07000",
    "#CD7F32",
    "#AAAAAA",
    "#BEC2CB",
    "#000000",
  ];

  // Esta função é usada pelo pai
  function handleSaveData(project) {
    // setData(project);
    // console.log(project);
    // props.setProjectData(project.name, project.projectname);
    if (project) {
      if (Consisted) {
        props.handleAdd();
      } else {
        toast.warn("Dados do projeto sem análise de consistência.");
      }
    }
  }

  async function handleAjust() {}

  // async function handleValidate() {
  //   if (!Consisted) {
  //     setLoading(true);
  //     const treesData = JSON.parse(sessionStorage.getItem("dataTrees"));
  //     const sectionsData = JSON.parse(sessionStorage.getItem("dataSections"));

  //     async function paralaleRequest() {
  //       const Allresponses = [];
  //       await axios
  //         .all([
  //           axios.post(
  //             "https://www.backfortrees.datagro.tec.br/consisthxd",
  //             treesData
  //           ),
  //           axios.post(
  //             "https://www.backfortrees.datagro.tec.br/consistdap",
  //             treesData
  //           ),
  //           axios.post(
  //             "https://www.backfortrees.datagro.tec.br/consistsections",
  //             sectionsData
  //           ),
  //         ])
  //         .then((responses) => {
  //           responses.forEach((resp, index) => {
  //             Allresponses[index] = resp.data;
  //           });
  //         });
  //       return Allresponses;
  //     }

  //     const Responses = await paralaleRequest();

  //     const response3 = await api.post("/globalhistogram", Responses[1]);

  //     transformData(Responses[0]);
  //     transformData(response3.data);
  //     transformData2(Responses[0]);
  //     transformData3(Responses[2]);

  //     sessionStorage.setItem("Consisted", true);
  //     setConsisted(true);
  //     setLoading(false);
  //     toast.success("Dados do projeto consistidos.");
  //   } else {
  //     toast.warn("Dados do projeto já foram consistidos.");
  //   }
  // }

  return (
    <>
      <Content>
        <Loading loading={loading} />
      </Content>

      {hasData ? (
        <>
          <Container>
            {Consisted ? (
              <>
                <div className="myDiv">
                  <button className="azul" type="submit" onClick={handleAjust}>
                    <h3>Ajustar modelo de afilamento</h3>
                  </button>
                </div>
                <div className="hide">
                  <FcInfo size={25} />
                  <h4>
                    A partir dos de cubagem validados é ajustado um modelo de
                    afilamento para o projeto
                  </h4>
                </div>
              </>
            ) : (
              <></>
            )}

            <div className="graphic">
              <h2>Dados de Afilamento</h2>
              <ResponsiveContainer width="100%" height={400}>
                <ScatterChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid />
                  <Tooltip cursor={{ strokeDasharray: "2 2" }} />

                  <XAxis
                    type="number"
                    dataKey="sectionhi"
                    name="hi"
                    unit="m"
                    position="end"
                    domain={["auto", "auto"]}
                    interval={"preserveStartEnd"}
                    tickCount={8}
                    allowDecimals={true}
                  >
                    <Label
                      value={"Altura da seção (m)"}
                      position="bottom"
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                    />
                  </XAxis>
                  <YAxis
                    type="number"
                    dataKey="sectiondicc"
                    name="di"
                    unit="cm"
                    domain={["auto", "auto"]}
                  >
                    <Label
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                      position="left"
                      angle={270}
                      value={"Diâmetro da seção (cm)"}
                    />
                  </YAxis>

                  {/* <Tooltip cursor={{ strokeDasharray: "3 3" }} /> */}

                  <Legend verticalAlign="top" height={36} />

                  {DataToPlot3.map((item, index) => (
                    <>
                      <Scatter
                        key={`cell-${index}`}
                        name={item.plot}
                        data={item.data}
                        // fill={`#${Math.floor(Math.random() * 999999)}`}
                        // fill={COLORS[Math.floor(Math.random() * COLORS.length)]}
                        fill={COLORS[index]}
                      />
                    </>
                  ))}
                </ScatterChart>
              </ResponsiveContainer>
            </div>

            <div className="myDiv">
              <button className="verde" type="submit" onClick={handleSaveData}>
                <h3>Avançar para 4ª etapa</h3>
              </button>
            </div>
            <div className="hide">
              <FcInfo size={25} />
              <h4>
                Avança para próxima etapa de processamento com os dados
                consistidos.
              </h4>
            </div>
          </Container>
        </>
      ) : (
        <>
          <div>
            <br />
            <p>Nenhum projeto carregado</p>
          </div>
        </>
      )}
    </>
  );
});

export default Step3;
