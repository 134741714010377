import styled from 'styled-components';

const Container = styled.div`
  width: 900px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  z-index: -1;

  .leaflet-bottom,
  .leaflet-right {
    height: 0;
  }
`;

export default Container;
