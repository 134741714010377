import styled from 'styled-components';

export const Container = styled.div`
  /* overflow: scroll; */
  margin-left: auto;
  margin-right: auto;

  img {
    width: 500px;
    height: auto;
  }
`;

export const CaptchaContainer = styled.div`
  /* display: flex; */
  /* align-self: center; */
  /* width: auto; */
  /* overflow: hidden; */
`;
