import styled from 'styled-components';
import { darken } from 'polished';

export const Div = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 100vw;
  flex-direction: column;
  align-content: center;
  max-width: auto;
  margin-left: auto;
  margin-right: auto;

  div {
    width: 100%;
    padding: 0;
    margin: 0;

    button {
      width: 100%;
    }
  }

  table {
    display: inline-block;
    margin: 0 auto;
    max-width: 100vw;
    padding-bottom: 100px;
    border-spacing: 0px;
    overflow: scroll;

    span {
      font-weight: bold;
      font-size: 20px;
    }

    div {
      text-align: center;
    }
    ::-webkit-scrollbar {
    display: none;
    }
  }

  tr:hover {
    background-color: rgb(245, 245, 245);
  }
  tr,
  th,
  td {
    margin: 0px;
    padding: 15px;
    border: 0px;
    text-align: center;
    vertical-align: center;
    font-size: 24px;
    border-bottom: 1px solid #ccc;
  }

  th {
    font-size: 18px;
    padding-top: 25px;
  }

  td {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  }

  button.verde {
    height: 44px;
    width: 400px;
    max-width: 100vw;
    margin-left: auto;
    margin-left: auto;
    padding: 0;
    background: rgba(0, 112, 0, 0.7);
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    margin-top: 30px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, 'rgba(0, 212, 0, 1)')};
  }


`;
