import React from "react";
import { NavLink } from "react-router-dom";

import { Container, Content } from "./styles";

function ProjectsNavigator() {
  return (
    <>
      <Container>
        <Content>
          <nav>
            <NavLink
              to="/projectsmanager/projectslist"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(0, 0, 0, 0.9)",
              }}
            >
              Lista de projetos
            </NavLink>
            <NavLink
              to="/projectsmanager/projectsregister"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(0, 0, 0, 0.9)",
              }}
            >
              Cadastrar novo projeto
            </NavLink>
            <NavLink
              to="/projectsmanager/projectupdate"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(0, 0, 0, 0.9)",
              }}
            >
              Editar um projeto
            </NavLink>
          </nav>
        </Content>
      </Container>
    </>
  );
}

export default ProjectsNavigator;
