import styled from 'styled-components';
import { darken } from 'polished';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  max-width: auto;
  margin: 0 auto;
  text-align: center;

  button {
    height: 44px;
    width: 400px;
    background: rgba(0, 112, 0, 0.7);
    font-weight: bold;
    color: #fff;
    border: 0;
    padding: 5px 5px;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, 'rgba(0, 212, 0, 1)')};
    }
  }
`;
