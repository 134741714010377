import React, { useState, useEffect } from "react";

import api from "~/services/api";

import { FcInfo } from "react-icons/fc";

import { parseISO, format } from "date-fns";
import pt from "date-fns/locale/pt";

import { toast } from "react-toastify";

import Loading from "~/components/Loading";

import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  BarChart,
  Label,
  // ScatterChart,
  // ZAxis,
  // Legend,
  // Scatter,
} from "recharts";

import { Container, Content } from "./styles";

function PlotAnalysis() {
  const [dataToTable, setDataToTable] = useState([]);
  const [formattedData, setformattedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processed, setProcessed] = useState(false);

  async function handleData(data) {
    const dataTable = [];

    // É melhor estruturar a consulta via backend como estava para árvores (Tree)

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      for (let j = 0; j < element.stands.length; j++) {
        const subelement = element.stands[j];

        for (let k = 0; k < subelement.plots.length; k++) {
          const subelement2 = subelement.plots[k];

          for (let s = 0; s < subelement2.inventories.length; s++) {
            const subelement3 = subelement2.inventories[s];

            for (let z = 0; z < subelement3.trees.length; z++) {
              const subelement4 = subelement3.trees[z];

              const dataItem = {
                talhao: subelement.standname,
                parc: subelement2.plotname,
                date: format(parseISO(subelement3.monthyear), "dd/MM/yyyy", {
                  locale: pt,
                }),
                arv: subelement4.tree,
                dap: subelement4.treedap,
              };

              dataTable.push(dataItem);
            }
          }
        }
      }
    }
    setDataToTable(dataTable);
  }

  function transformData(originalData) {
    const result = {};
    originalData.forEach((obj) => {
      if (!result[obj.name]) {
        result[obj.name] = { plot: obj.name, data: [] };
      }
      result[obj.name].data.push(obj);
    });
    setformattedData(Object.values(result));
  }

  useEffect(() => {
    async function loadTreesFromSession() {
      const dataSession = JSON.parse(sessionStorage.getItem("projectData"));

      if (dataSession !== null) {
        handleData(dataSession);
      }

      const histogramData = JSON.parse(sessionStorage.getItem("histogram"));

      if (histogramData !== null) {
        transformData(histogramData);
        setProcessed(true);
      }
    }

    loadTreesFromSession();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit() {
    setLoading(true);
    if (processed) {
      setLoading(false);
      return toast.warn("Dados do projeto já processados");
    }
    if (dataToTable.length > 1) {
      try {
        const response = await api.post("/histogram", dataToTable);
        // Aqui Devo inserir a resposta do backend
        transformData(response.data);
        sessionStorage.setItem("histogram", JSON.stringify(response.data));
        setProcessed(true);
        setLoading(false);
        toast.success("Histograma(s) calculado(s) com sucesso!");
      } catch (err) {
        // console.log(err);
        setLoading(false);
        toast.error("Não foi possível efetuar os cálculos. Verifique os dados");
      }
    } else {
      setLoading(false);
      toast.error("Sem dados para a análise.");
    }
  }

  return (
    <>
      <Container>
        <Content>
          <Loading loading={loading} />
          <div className="myDiv">
            <button type="submit" onClick={handleSubmit}>
              Calcular Histogramas
            </button>
          </div>
          <div className="hide">
            <FcInfo size={25} />
            <h4>Calcula os histogramas para cada parcela em cada idade.</h4>
          </div>
        </Content>

        <div className="graphicArea">
          {formattedData.map((e) => (
            <div key={e.plot} className="graphics">
              <h2>Parcela {e.plot}</h2>
              <ResponsiveContainer width="100%" height={300}>
                <BarChart
                  data={e.data}
                  margin={{
                    top: 0,
                    right: 0,
                    left: 20,
                    bottom: 60,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="classes">
                    <Label
                      value={"DAP (cm)"}
                      position="bottom"
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                    />
                  </XAxis>
                  <YAxis>
                    <Label
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                      position="left"
                      angle={270}
                      value={"Número de Árvores (n)"}
                    />
                  </YAxis>
                  <Tooltip />
                  {/* <Legend /> */}
                  <Bar dataKey="count" fill="#0000FF" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          ))}
        </div>
      </Container>
    </>
  );
}

export default PlotAnalysis;
