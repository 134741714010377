import L from 'leaflet';

const myIcon2 = L.icon({
  // iconUrl: require('../../assets/trees.png'),
  // iconUrl: 'https://www.svgrepo.com/show/227714/forest-pine.svg',
  iconUrl: 'https://unpkg.com/leaflet@1.5.1/dist/images/marker-icon.png',
  iconSize: [12, 24],
  iconAnchor: [10, 10],
  // popupAnchor: null,
  // shadowUrl: null,
  // shadowSize: null,
  // shadowAnchor: null,
});

export default myIcon2;
