import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  display: flex;
  max-width: 100vw;
  flex-wrap: nowrap;
  flex-direction: column;
  align-content: center;
  margin: 5px;
  height: 100vh;
  overflow: scroll;

  button {
    background: none;
    border: 0;
    div {
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  /* div {
    width: 900px;
    padding: 5px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: -3px 2px 3px 2px #aaa;
    h6 {
      font-weight: bold;
    }
  } */

  table {
    /* span {
      font-weight: bold;
      font-size: 20px;
    } */

    div {
      overflow: hidden;
      text-align: center;
    }
  }
  /*

  tr:hover {
    background-color: rgb(245, 245, 245);
  }
  tr,
  th,
  td {
    margin: 0px;
    padding: 15px;
    border: 0px;
    text-align: center;
    vertical-align: center;
    font-size: 24px;
    border-bottom: 1px solid #ccc;
  }

  th {
    font-size: 18px;
  }

  td {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  } */

  ::-webkit-scrollbar {
    width: 10px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 112, 0, 1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${darken(0.03, "rgba(0, 112, 0, 0.7)")};
  }
`;
