import styled from "styled-components";

export const Container = styled.div`
  @media only screen and (max-width: 768px) {
    display: block;
    margin-left: auto;
    margin-right: auto;
    form {
      display: block;
    }
    nav {
      a {
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }
    }
  }
  width: 100%;
  max-height: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  /* flex-wrap: nowrap; */
  flex-direction: column;
  align-content: center;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  form {
    select,
    div {
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
