import styled from "styled-components";
// import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  margin: 0;
  padding: 0;
  border: 0;
  padding-bottom: 50px;

  @media only screen and (max-width: 768px) {
    width: 90vw;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: scroll;
    margin-left: auto;
    margin-right: auto;
    align-items: center;
    align-content: center;
    text-align: center;

    img,
    img.system {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    h1,
    h2,
    h3 {
      width: 100%;
      display: flex;
      align-items: center;
      align-content: center;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      flex-wrap: wrap;
      /* font-size: 1em; */
    }
    div {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      align-items: center;
      align-content: center;
      text-align: center;
      img {
        display: flex;
        /* flex-wrap: wrap; */
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  div {
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    /* width: 900px; */
    text-align: justify;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    overflow: scroll;

    h1 {
      font-size: 36px;
    }
    h2 {
      color: white;
    }

    h3 {
      text-align: justify;
      padding-top: 25px;
    }

    h3 {
      text-align: justify;
    }

    img {
      max-width: 200px;
      height: auto;
    }

    img.system {
      max-width: 400px;
      height: auto;
    }
  }
`;
