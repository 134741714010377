import React from "react";

import * as Yup from "yup";
import PropTypes from "prop-types";

import { toast } from "react-toastify";
import { Form, Input } from "@rocketseat/unform";

// import ReCAPTCHA from "react-google-recaptcha";
import history from "~/services/history";
import { Container } from "./styles";

import logo from "~/assets/trees.png";

import api from "~/services/api";

const schema = Yup.object().shape({
  newpassword: Yup.string().required("Insira sua senha"),
  confirmNewpassword: Yup.string().required("Repita sua senha"),
});

function UserReset({ token, subdomain }) {
  // const recaptchaRef = useRef();

  async function handleButton(dataForm) {
    // const recaptchaValue = recaptchaRef.current.getValue();

    // dataForm.recaptchaValue = recaptchaValue;
    // if (recaptchaValue) {
    try {
      await api.post(`/${subdomain}/${token}`, dataForm);
      toast.success("Senha Atualizada com Sucesso");
      await new Promise((resolve) => setTimeout(resolve, 5000));
      history.push("/");
    } catch (err) {
      toast.error("Não é mais possível atualizar esta senha");
      await new Promise((resolve) => setTimeout(resolve, 5000));
      history.push("/");
    }
    // } else {
    //   toast.error("Confime que você não é um robô.");
    // }
  }

  // function onChange() {
  //   const recaptchaValue = recaptchaRef.current.getValue();

  //   // console.log('Captcha value:', captchaToken);

  //   // console.log(recaptchaValue);

  //   if (recaptchaValue) {
  //     console.log('True');
  //   }
  // }
  return (
    <Container>
      <Form onSubmit={handleButton} schema={schema}>
        <img src={logo} alt="GoBarber" />
        <h2>ForTrees</h2>
        <Input
          name="newpassword"
          // value={newpassword}
          // onChange={setNewpassword}
          type="password"
          placeholder="Sua nova senha"
        />
        <Input
          name="confirmNewpassword"
          // value={confirmNewpassword}
          // onChange={setConfirmNewpassword}
          type="password"
          placeholder="Repita sua nova senha"
        />

        {/* <CaptchaContainer>
          <ReCAPTCHA
            className="ReCAPTCHA"
            sitekey="6Lda8tshAAAAAErCIEBDaDcV5Ki7o31CT-RhCm3C"
            // onChange={onChange}
            ref={recaptchaRef}
            theme="dark"
          />
        </CaptchaContainer> */}
        <button type="submit">
          <p>Enviar</p>
        </button>
      </Form>
    </Container>
  );
}

UserReset.propTypes = {
  token: PropTypes.string.isRequired,
  subdomain: PropTypes.string.isRequired,
};

export default UserReset;
