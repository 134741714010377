import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  padding: 0 auto;
  width: 100vw;
  margin: 0 auto;
`;

export const Content = styled.div`
  @media only screen and (max-width: 768px) {
    display: block;
  }
  width: 100vw;
  margin: 0 auto;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    a {
      font-size: 16px;
      margin: 10px;
      font-weight: bold;
      color: #333;

      &:hover {
        transition: color 0.3s;
        color: #000;
      }
    }
  }

  form {
    select,
    input {
      width: 200px;
      background: rgba(0, 0, 0, 0.07);
      border: 0;
      border-radius: 4px;
      height: 44px;
      color: #000;
      margin: 10px;
      padding: 5px;
    }

    button {
      height: 44px;
      max-width: 200px;
      min-width: 100px;
      background: rgba(0, 112, 0, 0.7);
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, "rgba(0, 212, 0, 1)")};
      }
    }
  }
`;
