import React from "react";

import { Container } from "./styles";
import logo from "~/assets/trees.png";
import ForTrees from "~/assets/3b.png";

function DataPolicys() {
  return (
    <Container>
      <div>
        <img src={logo} alt="ForTrees" />
      </div>
      <div>
        <h1>ForTrees</h1>
        <h2>Política de Dados</h2>
      </div>

      <div>
        <img className="system" src={ForTrees} alt="Google Example" />
      </div>
      <div>
        <h2>Sobre o sistema:</h2>
        <h3>
          O Sistema ForTrees é um sistema de apoio ao Engenheiro Florestal para
          o planejamento e execução de inventários florestais. O próprio sistema
          é para a coleta e processamento de dados florestais.
        </h3>
        <h3>
          As informações armazenadas estarão disponíveis para o usuário até um
          ano de carência após o vencimento da assinatura.
        </h3>
        <br />
        <h2>Dados pessoais e privacidade do usuário:</h2>
        <h3>
          As informações pessoais incluem as informações cadastrais como: nome,
          email, cpf e informações de endereço para emissão de nota fiscal ao
          usuário.
        </h3>
        <h3>
          O aplicativo de campo solicitará acesso a localização para navegação
          de campo; além da disponibilidade de rede de dados para baixar ou
          subir os dados.
        </h3>
      </div>
      <div>
        <h2>Sobre o uso dos dados pessoais:</h2>
        <h3>
          1. Os dados que você compartilhar com esse sistema não serão
          compartilhados com terceiros em nenhuma hipótese.
        </h3>
      </div>
      <div>
        <h3>
          2. Os dados utilizados pelo sistema servem unicamente para atender o
          funcionamento do sistema.
        </h3>
      </div>
    </Container>
  );
}

export default DataPolicys;
