import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 10%;
  width: 100vw;
`;

export const Content = styled.div`
  height: 72px;
  /* max-width: 900px; */
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  nav {
    display: flex;
    align-items: center;
    a {
      font-size: 16px;
      margin: 10px;
      font-weight: bold;
      color: #333;

      &:hover {
        transition: color 0.3s;
        color: #000;
      }
    }
  }
`;
