import React from "react";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { Router } from "react-router-dom";

import { Helmet } from "react-helmet";

import "~/config/ReactotronConfig";
import Routes from "~/routes";

import history from "~/services/history";

import { store, persistor } from "./store";

import GlobalStyle from "~/styles/global";

function App() {
  return (
    <>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Helmet>
              <title>ForTrees</title>
              <link
                rel="icon"
                type="image/png"
                href="favico.ico"
                sizes="16x16"
              />
            </Helmet>
            <Routes />
            <GlobalStyle />
            <ToastContainer autoclose={3000} />
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
