/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Select } from '@rocketseat/unform';
import MUIDataTable from 'mui-datatables';

import { toast } from 'react-toastify';
import api from '~/services/api';

import { Div } from './styles';

function ServiceStep1(props) {
  const [projects, setMyProjects] = useState([]);
  const [projectOne, setprojectOne] = useState();
  const [rowsSelected, setRowsSelected] = useState([]);
  const [newdata, setNewData] = useState([]);

  const options = {
    filterType: 'checkbox',
    responsive: 'standard',
    print: false,
    selectableRows: 'multiple',
    selectableRowsHeader: false,
    selectableRowsOnClick: true,
    rowsSelected,
    // eslint-disable-next-line no-shadow
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      // console.log(rowsSelectedData, allRows, rowsSelected);
      setRowsSelected(rowsSelected);
      setNewData(rowsSelectedData);
    },
    // onRowClick: rowData => console.log(rowData),
    fixedSelectColumn: false,
    selectToolbarPlacement: 'none',
    fixedHeader: true,
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    download: false,
    // downloadOptions: {
    //   filterOptions: {
    //     useDisplayedRowsOnly: true,
    //     useDisplayedColumnsOnly: true,
    //   },
    // },
    pagination: true,
    textLabels: {
      body: {
        noMatch: 'Desculpe, nenhum registro coincide',
        toolTip: 'Ordenar',
        columnHeaderTooltip: column => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: 'Próxima página',
        previous: 'Página anterior',
        rowsPerPage: 'Linhas por página:',
        displayRows: 'de',
      },
      toolbar: {
        search: 'Buscar',
        downloadCsv: 'Download CSV',
        print: 'Imprimir',
        viewColumns: 'Ver Colunas',
        filterTable: 'Filtrar tabela',
      },
      filter: {
        all: 'TUDO',
        title: 'FILTROS',
        reset: 'RESETAR',
      },
      viewColumns: {
        title: 'Mostrar colunas',
        titleAria: 'Mostrar/Esconder colunas da tabela',
      },
      selectedRows: {
        text: 'Linhas selecionadas',
        delete: 'Deletar',
        deleteAria: 'Deletar linhas selecionadas',
      },
    },
  };

  const columns = [
    {
      label: 'Talhão',
      name: 'talhao',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Parcela',
      name: 'parcela',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: 'Latitudeº',
      name: 'coordx',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      label: 'Longitudeº',
      name: 'coordy',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  useEffect(() => {
    async function loadMyProjects() {
      const response = await api.get('/myprojects');

      const data = response.data.map(project => ({
        ...project,
      }));

      const projectOptions = data.map(e => {
        return {
          id: e.projectname,
          title: e.projectname,
        };
      });

      setMyProjects(projectOptions);
    }
    loadMyProjects();
  }, []);

  async function projectList({ projectName }) {
    // setProjectSelected(projectName);
    const body = {
      projectname: projectName,
    };
    const response = await api.post('/projectsfind', body);

    const data = response.data.map(project => ({
      ...project,
    }));

    const tableArray = [];
    data.map(project =>
      project.stands.map(s =>
        s.plots.map(p =>
          tableArray.push({
            projeto: project.projectname,
            talhao: s.standname,
            plot_id: p.id,
            parcela: p.plotname,
            coordx: p.coordx.toFixed(4),
            coordy: p.coordy.toFixed(4),
            collector: p.current_collector,
          })
        )
      )
    );

    setprojectOne(tableArray);

    if (tableArray.length === 0) {
      toast.error('Este projeto não possui nenhuma parcela cadastrada!');
    }

    const projectResume = data.map(project => ({
      projectname: project.projectname,
      projectowner: project.projectowner,
    }));

    sessionStorage.setItem('projectOnly', JSON.stringify(projectName));

    sessionStorage.setItem('projectSelected', JSON.stringify(projectResume[0]));
  }

  function handleAddPlots() {
    const imprime = newdata.map(e => ({
      standname: e.talhao,
      plotname: e.parcela,
      plotcoordx: e.coordx,
      plotcoordy: e.coordy,
      plot_id: e.plot_id,
      collector: e.collector,
    }));

    if (imprime.length !== 0 || null) {
      sessionStorage.setItem('selectedPlots', JSON.stringify(imprime));
      props.handleAdd();
    } else {
      toast.error('Nenhuma parcela selecionada!');
    }
  }

  useEffect(() => {
    rowsSelected.forEach(e => {
      newdata.push(projectOne[e]);
    });

    newdata.shift();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsSelected]);

  return (
    <>
      <div>
        <Form onSubmit={projectList}>
          <div>
            <h3>1.1 Selecione o projeto para listagem das parcelas:</h3>
            <Select
              required
              name="projectName"
              options={projects}
              placeholder="Projeto"
            />
          </div>
          <div>
            <button className="azul" type="submit">
              Listar as parcelas do projeto
            </button>
          </div>
        </Form>
        <br />
        <Div>
          <h3>1.2 Selecione as parcelas a serem medidas:</h3>
        </Div>
        <MUIDataTable data={projectOne} columns={columns} options={options} />
      </div>
      <Div>
        <button className="verde" type="submit" onClick={handleAddPlots}>
          Salvar
        </button>
      </Div>
    </>
  );
}

export default ServiceStep1;
