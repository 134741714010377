import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { Container } from './styles';

function Dashcard({ name, number, link }) {
  return (
    <>
      <Container>
        <div>
          <Link to={link}>
            <h1>{name}</h1>
            <h2>{number}</h2>
          </Link>
        </div>
      </Container>
    </>
  );
}

Dashcard.defaultProps = {
  number: 0,
};

Dashcard.propTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  number: PropTypes.number,
};

export default Dashcard;
