import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Form, Select, Input } from "@rocketseat/unform";
import { toast } from "react-toastify";
import history from "~/services/history";
import api from "~/services/api";

import { Container, Content } from "./styles";

function AnalyticsNavigator() {
  const [projects, setMyProjects] = useState([]);
  const [search, setSearch] = useState("");

  useEffect(() => {
    async function loadMyProjects() {
      const response = await api.get("/myprojects");

      const data = response.data.map((project) => ({
        ...project,
      }));

      // setMyProjectsFiltered(data);

      const projectOptions = data.map((e) => {
        return {
          id: e.id,
          title: e.projectname,
        };
      });

      setMyProjects(projectOptions);
    }
    loadMyProjects();
  }, []);

  const projectsFiltered =
    search.length > 0
      ? projects.filter((p) => p.title.includes(search))
      : projects;

  async function projectList({ projectName }) {
    const body = {
      id: projectName,
    };

    const response = await api.post("/projectdata", body);

    const data2 = response.data.map((project) => ({
      ...project,
    }));

    sessionStorage.setItem("projectData", JSON.stringify(data2));
    sessionStorage.removeItem("projectFullData");
    sessionStorage.removeItem("histogram");
    sessionStorage.removeItem("DataToPlot");
    sessionStorage.removeItem("DataToPlot2");
    sessionStorage.removeItem("DataToPlot3");
    sessionStorage.removeItem("Consisted");
    sessionStorage.removeItem("dataTrees");
    sessionStorage.removeItem("dataSections");
    sessionStorage.removeItem("smalianData");
    sessionStorage.removeItem("Consisted");
    sessionStorage.removeItem("HipsoModel");
    sessionStorage.removeItem("HDTable");
    history.push("/analytics");
    toast.success("Projeto carregado com sucesso!");
  }

  return (
    <>
      <Container>
        <Content>
          <Form onSubmit={projectList}>
            <Input
              name="search"
              type="buscar"
              placeholder="Filtrar"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <Select
              required
              name="projectName"
              options={projectsFiltered}
              placeholder="Projetos"
            />
            <div>
              <button type="submit">Carregar o projeto</button>
            </div>
          </Form>
          <nav>
            <NavLink
              to="/analytics/cubtrees"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(0, 0, 0, 0.9)",
              }}
            >
              Cubagens
            </NavLink>
            <NavLink
              to="/analytics/plotanalysis"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(0, 0, 0, 0.9)",
              }}
            >
              Histogramas
            </NavLink>
            <NavLink
              to="/analytics/inventoryanalysis"
              activeStyle={{
                color: "black",
                borderBottom: "solid 1px rgba(0, 0, 0, 0.9)",
              }}
            >
              Inventários
            </NavLink>
          </nav>
        </Content>
      </Container>
    </>
  );
}

export default AnalyticsNavigator;
