import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  overflow: scroll;
  padding-bottom: 200px;

  button.stepper {
    height: 44px;
    margin-left: 10px;
    width: 100px;
    padding-left: auto;
    padding-right: auto;
    background: rgba(212, 212, 212, 0.2);
    font-weight: bold;
    color: #1976d2;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, "rgba(25, 118, 210, 0.1)")};
    }
  }

  select {
    background: rgba(0, 0, 0, 0.07);
    border: 0;
    border-radius: 4px;
    height: 44px;
    color: rgba(0, 0, 0, 0.87);
    margin: 10px;
    padding: 5px;
  }

  div {
    align-content: center;
    width: auto;
    height: auto;
    padding: 0;
    margin-left: auto;
    margin-right: auto;
  }

  button.verde,
  .azul {
    padding: 10px;
    padding-left: auto;
    padding-right: auto;
    height: 44px;
    width: 300px;
    max-width: 100%;
    background: rgba(0, 112, 0, 0.7);
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, "rgba(0, 212, 0, 1)")};
    }
  }

  button.azul {
    background: rgba(0, 0, 112, 0.7);
    &:hover {
      background: ${darken(0.03, "rgba(0, 0, 212, 1)")};
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 112, 0, 1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${darken(0.03, "rgba(0, 112, 0, 0.7)")};
  }
`;
