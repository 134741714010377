import React from 'react';
import { useParams } from 'react-router-dom';

// import { Form, Input } from '@rocketseat/unform';

// import * as Yup from 'yup';

import Manager from '../../components/UserReset';

import { Container } from './styles';

// const schema = Yup.object().shape({
//   password: Yup.string().required('A senha é obrigatória'),
//   confirmpassword: Yup.string().required('A senha é obrigatória'),
// });

function ManagerForgotPass() {
  const { token } = useParams();

  return (
    <>
      <Container>
        <Manager token={token} subdomain="managerecover" />
      </Container>
    </>
  );
}

export default ManagerForgotPass;
