/* eslint-disable no-plusplus */
import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { parseISO, format } from "date-fns";
import pt from "date-fns/locale/pt";
// import api from '~/services/api';

import { Container } from "./styles";

function DataViewer() {
  // const [trees, setTrees] = useState([]);

  const [dataToTable, setDataToTable] = useState([]);

  // const [fazendaData, setFazendaData] = useState([]);

  const [rowsSelected, setRowsSelected] = useState([]);

  // const [newdata, setNewData] = useState([]);

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    selectableRows: "none",
    selectableRowsOnClick: false,
    rowsSelected,
    // eslint-disable-next-line no-shadow
    onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
      // console.log(rowsSelectedData, allRows, rowsSelected);
      setRowsSelected(rowsSelected);
      // setNewData(rowsSelectedData);
    },
    // onRowClick: rowData => console.log(rowData),
    fixedSelectColumn: false,
    fixedHeader: true,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    pagination: true,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro para o projeto.",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "TUDO",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
      selectedRows: {
        text: "Linhas selecionadas",
        delete: "Deletar",
        deleteAria: "Deletar linhas selecionadas",
      },
    },
  };

  const columns = [
    {
      label: "Projeto",
      name: "Projeto",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Talhão",
      name: "Talhão",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Parcela",
      name: "Parcela",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Inventário (Data)",
      name: "Inventário (Data)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Coletor",
      name: "Coletor",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "tree",
      label: "Árvore",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "treedap",
      label: "DAP (cm)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "treeht",
      label: "Altura total(m)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      name: "treehc",
      label: "Altura comercial (m)",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  // useEffect(() => {
  //   rowsSelected.forEach(e => {
  //     newdata.push(trees[e]);
  //   });

  //   newdata.shift();

  //   // const imprime = newdata.map(e => e.treedap);

  //   // console.log(newdata);
  //   // console.log(imprime);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [rowsSelected]);

  // useEffect(() => {
  //   async function loadTrees() {
  //     const response = await api.get('/trees');

  //     const data = response.data.map(tree => ({
  //       ...tree,
  //     }));

  //     // const imprime = newdata.map(e => e.);

  //     // const inventoryDate = data.map(d =>
  //     //   format(parseISO(d.inventories.monthyear), 'MMMM')
  //     // );

  //     data.forEach(d => {
  //       d.inventories.monthyear = format(
  //         parseISO(d.inventories.monthyear),
  //         'MMMM/yyyy',
  //         { locale: pt }
  //       );
  //     });
  //     // console.log(inventoryDate);

  //     setTrees(data);
  //   }
  //   loadTrees();
  // }, []);

  function handleData(data) {
    const dataTable = [];

    // É melhor estruturar a consulta via backend como estava para árvores (Tree)

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      for (let j = 0; j < element.stands.length; j++) {
        const subelement = element.stands[j];

        for (let k = 0; k < subelement.plots.length; k++) {
          const subelement2 = subelement.plots[k];

          for (let s = 0; s < subelement2.inventories.length; s++) {
            const subelement3 = subelement2.inventories[s];

            for (let z = 0; z < subelement3.trees.length; z++) {
              const subelement4 = subelement3.trees[z];

              const dataItem = {
                projeto: element.projectname,
                talhao: subelement.standname,
                parcela: subelement2.plotname,
                data: format(parseISO(subelement3.monthyear), "MMMM/yyyy", {
                  locale: pt,
                }),
                coletor: subelement3.collector_name,
                arvore: subelement4.tree,
                dap: subelement4.treedap,
                ht: subelement4.treeht,
                hc: subelement4.treehc,
              };
              dataTable.push(dataItem);
            }
          }
        }
      }
    }

    setDataToTable(dataTable);
  }

  useEffect(() => {
    async function loadTreesFromSession() {
      const dataSession = JSON.parse(sessionStorage.getItem("projectData"));

      if (dataSession !== null) {
        // const DataFormated = dataSession.map(fazenda => ({
        //   ...fazenda, // aqui é o contrário 1 pra muitos
        // }));

        handleData(dataSession);

        // setFazendaData(DataFormated);
      }
    }

    loadTreesFromSession();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Container>
        {/* <MUIDataTable
          title="Árvores Amostradas"
          data={trees.map(tree => {
            return [
              tree.inventories.plot.stand.project.projectname,
              tree.inventories.plot.stand.standname,
              tree.inventories.plot.plotname,
              tree.inventories.monthyear,
              tree.inventories.collector.name,
              tree.tree,
              tree.treedap,
              tree.treeht,
              tree.treehc,
            ];
          })}
          columns={columns}
          options={options}
        />
        <MUIDataTable
          title="Árvores Amostradas"
          data={fazendaData.map(faz => {
            return [
              faz.projectname,
              faz.stands.standname,
              faz.projectname,
              faz.projectname,
              faz.projectname,
              faz.projectname,
              faz.projectname,
              faz.projectname,
              faz.projectname,
            ];
          })}
          columns={columns}
          options={options}
        /> */}
        <MUIDataTable
          title="Árvores Amostradas"
          data={dataToTable.map((d) => {
            return [
              d.projeto,
              d.talhao,
              d.parcela,
              d.data,
              d.coletor,
              d.arvore,
              d.dap.toFixed(1),
              d.ht ? d.ht.toFixed(1) : null,
              d.hc ? d.hc.toFixed(1) : null,
            ];
          })}
          columns={columns}
          options={options}
        />
      </Container>
    </>
  );
}

export default DataViewer;
