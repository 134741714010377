import React, { useEffect } from 'react';
import { Route } from 'react-router-dom';
import history from '~/services/history';
import { Container } from './styles';

import ProjectsNavigator from '~/components/ProjectsNavigator';

import ProjectsList from './ProjectsList';
import ProjectsRegister from './ProjectsRegister';
import ProjectUpdate from './ProjectUpdate';

function ProjectsManager() {
  useEffect(() => {
    history.push('/projectsManager/projectslist');
  }, []);

  return (
    <>
      <Container>
        <ProjectsNavigator />
        <Route
          path="/projectsManager/projectslist"
          isPrivate
          component={ProjectsList}
        />
        <Route
          path="/projectsManager/projectsregister"
          isPrivate
          component={ProjectsRegister}
        />
        <Route
          path="/projectsManager/projectupdate"
          isPrivate
          component={ProjectUpdate}
        />
      </Container>
    </>
  );
}

export default ProjectsManager;
