import styled, { css } from "styled-components";

export const Container = styled.div`
  h1,
  img {
    color: black;
  }
  ${(props) =>
    props.type === "open" &&
    css`
      display: block;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 200;
      height: 100vh;
      width: 100vw;
      transition: 0.1s;

      div {
        display: flex;
        flex-direction: column;
        background-color: rgba(255, 255, 255, 0.7);
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
      }
    `}

  ${(props) =>
    props.type === "close" &&
    css`
      transition: 0.5s;
      display: none;
    `}
`;
