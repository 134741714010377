import React, { useEffect, useState } from "react";
import Dashcard from "~/components/Dashcard";
// import Leaflet from '~/components/Leaflet';

import Loading from "~/components/Loading";
import api from "~/services/api";

import { Container } from "./styles";

function Dashboard() {
  const [dashboard, setDashboard] = useState([]);
  const [loading, setLoading] = useState(false);

  /**
   * Ao invés de usar o REDIS estou usando o SessionStorage e um gap time de 1 minuto; Dashboard, Services e Data
   * Na verdade... a maioria das páginas somente o Manager pode alterar
   * Services e Data são os únicos que precisam receber atualização por tempo; os outros pode ser somente quando o Manager fizer alterações;
   */

  useEffect(() => {
    setLoading(true);
    async function loadDashboard() {
      const alreadyLoaded = JSON.parse(sessionStorage.getItem("DashboardInfo"));
      const refreshedTime =
        JSON.parse(sessionStorage.getItem("DashRefreshTime")) || new Date();
      let now = new Date().valueOf();

      if (alreadyLoaded && now <= refreshedTime) {
        setDashboard(alreadyLoaded);
        setLoading(false);
      } else {
        const response = await api.get("/dashboard");
        const loadedTime = new Date();
        const refresh = loadedTime.setTime(
          loadedTime.valueOf() + 1000 * 60 * 5
        );
        sessionStorage.setItem("DashRefreshTime", refresh);
        sessionStorage.setItem("DashboardInfo", JSON.stringify(response.data));

        setDashboard(response.data);
        setLoading(false);
      }
    }
    loadDashboard();
  }, []);

  return (
    <>
      <Container>
        <Loading loading={loading} />
        <Dashcard
          name="Projetos"
          number={dashboard.projetos}
          link="/projectsmanager"
        />
        <Dashcard
          name="Talhões"
          number={dashboard.talhoes}
          link="/projectsmanager"
        />
        <Dashcard
          name="Parcelas"
          number={dashboard.parcelas}
          link="/mydata/dataviewer"
        />
        <Dashcard
          name="Coletores"
          number={dashboard.coletores}
          link="/collectors"
        />
        <Dashcard
          name="Árvores"
          number={dashboard.arvores}
          link="/mydata/dataviewer"
        />
        <Dashcard
          name="Cubagens"
          number={dashboard.cubagens}
          link="/mydata/cubviewer"
        />
      </Container>
    </>
  );
}

export default Dashboard;
