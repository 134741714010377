import produce from "immer";

const INITIAL_STATE = {
  profile: null,
};

export default function manager(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@auth/SIGN_IN_SUCCESS": {
        draft.profile = action.payload.manager;
        break;
      }
      case "@manager/UPDATE_PROFILE_SUCCESS": {
        draft.profile = action.payload.profile;
        break;
      }
      case "@auth/SIGN_OUT": {
        // draft.profile = null; // DesativEI para não quebrar tokensignout
        draft.profile.signindate = null;
        localStorage.removeItem("persist:fortrees");
        sessionStorage.clear();
        break;
      }
      default:
    }
  });
}
