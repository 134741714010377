import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;

  button .button {
    cursor: pointer;
  }

  img {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  div {
    width: auto;
  }
`;

export const CaptchaContainer = styled.div`
  display: flex;
  align-self: center;
  /* width: auto; */
  /* overflow: hidden; */
`;
