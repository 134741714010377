import React from "react";
import { Switch } from "react-router-dom";
import Route from "./Route";
import CollectorRegister from "~/pages/CollectorRegister";
import CollectorForgotPass from "~/pages/CollectorForgotPass";
import ManagerForgotPass from "~/pages/ManagerForgotPass";
import UserValidation from "~/pages/UserValidation";
import Dashboard from "~/pages/Dashboard";
import DataPolicys from "~/pages/DataPolicys";
import ProjectsManager from "~/pages/ProjectsManager";
import MyData from "~/pages/MyData";
import Profile from "~/pages/Profile";
import Newservice from "~/pages/Newservice";

import SignIn from "~/pages/SignIn";
import ForgotPassword from "~/pages/ForgotPassword";
import Analytics from "~/pages/Analytics";

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/forgotpass" component={ForgotPassword} />
      <Route
        path="/collectorforgotpass/:token"
        component={CollectorForgotPass}
      />
      <Route path="/uservalidation/:token" component={UserValidation} />
      <Route path="/managerforgotpass/:token" component={ManagerForgotPass} />
      <Route path="/datapolicys" component={DataPolicys} />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route path="/projectsmanager" component={ProjectsManager} isPrivate />
      <Route path="/collectors" component={CollectorRegister} isPrivate />
      <Route path="/mydata" component={MyData} isPrivate />
      <Route path="/services" component={Newservice} isPrivate />
      <Route path="/analytics" component={Analytics} isPrivate />
    </Switch>
  );
}
