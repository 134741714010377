import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input } from "@rocketseat/unform";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import logo from "~/assets/trees.png";

import { Container } from "./styles";

import { signInRequest } from "~/store/modules/auth/actions";

const schema = Yup.object().shape({
  email: Yup.string()
    .email("Insira um e-mail válido")
    .required("O e-mail é obrigatório"),
  password: Yup.string().required("A senha é obrigatória"),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <Container>
      <Form schema={schema} onSubmit={handleSubmit}>
        <img src={logo} alt="ForTrees" />
        <h2>ForTrees</h2>
        <Input name="email" type="email" placeholder="Seu e-mail" />
        <Input
          name="password"
          type="password"
          placeholder="Sua senha secreta"
        />
        <Link to="/forgotpass">Esqueci minha senha</Link>
        <Link to="/datapolicys">Política de dados</Link>

        <button type="submit">{loading ? "Carregando..." : "Entrar"}</button>
      </Form>
    </Container>
  );
}
