import styled from "styled-components";
import { darken } from "polished";

export const Table = styled.table`
  table {
    display: inline-block;
    margin: 0 auto;
    border-spacing: 0px;
    span {
      font-weight: bold;
      font-size: 20px;
    }
    div {
      text-align: center;
    }
  }
`;

export const Container = styled.div`
  @media only screen and (max-width: 768px) {
    display: flex;
    height: auto;
    overflow: scroll;
  }

  max-width: 100vw;
  height: auto;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  max-height: auto;
  overflow: scroll;

  ::-webkit-scrollbar {
    display: none;
  }

  z-index: 0;

  h6 {
    font-size: 24px;
    font-weight: bold;
  }
  div.table {
    width: 900px;
    padding: 5px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
    box-shadow: -3px 2px 3px 1px #aaa;
    border-radius: 5px;
  }
  table {
    display: inline-block;
    margin: 0 auto;
    border-spacing: 0px;
    span {
      font-weight: bold;
      font-size: 20px;
    }
    div {
      text-align: center;
    }
  }
  tr:hover {
    background-color: rgb(245, 245, 245);
  }
  tr,
  th,
  td {
    margin: 0px;
    padding: 5px;
    border: 0px;
    text-align: center;
    vertical-align: center;
    font-size: 24px;
    border-bottom: 1px solid #ccc;
    p {
      display: inline;
    }
  }
  th {
    font-size: 18px;
    padding-top: 0;
  }
  td {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }
  ::-webkit-scrollbar-thumb {
    background: rgba(0, 112, 0, 1);
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${darken(0.03, "rgba(0, 112, 0, 0.7)")};
  }
`;
