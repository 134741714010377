import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  max-width: 600px;
  height: auto;
  margin: 0px auto;
  overflow: hidden;

  :-webkit-scrollbar {
    display: none;
  }

  div {
    width: 100%;
  }

  form {
    width: 100%;
    overflow: hidden;
    :-webkit-scrollbar {
      display: none;
    }
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    margin-top: 20px;
    input {
      background: rgba(0, 0, 0, 0.1);
      border: 0;
      border-radius: 4px;
      height: 44px;
      padding: 0 5px;
      color: #000;
      margin: 0 0 5px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
    span {
      color: #fb6f91;
      align-self: flex-start;
      margin: 0 0 10px;
      font-weight: bold;
    }
    hr {
      border: 0;
      height: 1px;
      background: rgba(0, 0, 0, 0.1);
      margin: 10px 0 20px;
    }
    button {
      margin: 5px 0 0;
      height: 44px;
      background: rgba(0, 112, 0, 0.7);
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: rgba(0, 112, 0, 1);
      }
    }
    button.sair {
      width: 100%;
      margin: 10px 0;
      margin-bottom: 100px;
      height: 44px;
      background: #4040ff;
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.1, '#0000ff')};
      }
    }
  }

  img {
    align-self: center;
    width: 200px;
    height: auto;
  }
`;
