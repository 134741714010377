import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  vertical-align: top;

  div {
    margin: 0;
    padding: 0;
    border: 0;
  }
`;
