import styled, { css } from "styled-components";

export const ButtonIcon = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
    position: absolute;
    left: 10px;
    top: 10px;
    height: auto;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px
    z-index: 201;

    button {
      font-size: 42px;
      font-weight: 700;
      color: #000;
      background: none;
      border: none;
    }

    ${(props) =>
      props.type === "open" &&
      css`
        margin-left: 100px;
        transition: 0.5s;
        transform: rotateZ(180deg);
      `}

    ${(props) =>
      props.type === "close" &&
      css`
        transition: 0.5s;
        transform: rotateZ(0deg);
      `}
  }
`;

export const Container = styled.div`
  display: block;
  @media only screen and (max-width: 768px) {
    top: 0;
    left: 0;
    background-color: rgba(0, 70, 0, 0.95);
    display: block;
    position: absolute;
    width: auto;
    float: left;
    height: 100vh;
    wrap: nowrap;
    transform: translateX(0%);
    transition: 0.25s;
    z-index: 200;

    ${(props) =>
      props.type === "close" &&
      css`
        transition: 0.5s;
        transform: translateX(-100%);
      `}

    ${(props) =>
      props.type === "open" &&
      css`
        transition: 0.5s;
        transform: translateX(0%);
      `}

    nav {
      width: auto;
      height: auto;

      aside {
        height: auto;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        margin-right: auto;

        div {
          padding-top: 0px;
          div {
            display: none;
          }
        }
      }
    }
  }

  display: flex;
  align-self: center;
  background: #007000;
  margin-left: auto;
  margin-right: auto;
  padding-left: auto;
  padding-right: auto;
  width: 100vw;
  height: auto;

  nav {
    /* display: block;
    float: right; */
    width: auto;
    padding-left: auto;
    padding-right: auto;
    margin-left: auto;
    margin-right: auto;
    /* padding: 0 25%; */

    aside {
      display: flex;
      width: auto;
      /* float: right; */
      div {
        display: block;
        float: left;
      }
    }
  }
`;

export const Content = styled.div`
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: row;
    max-height: auto;
    max-width: auto;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: none;
    align-items: center;
    overflow: scroll;

    nav {
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      /* max-width: 20vw; */
      height: 100vh;
      /* padding: 0px; */
      margin-left: 0;
      margin-right: 0;

      div {
        padding: 0px;
      }

      img {
        padding: 0px;

        a {
          margin: 0px;
        }
      }
    }

    aside {
      width: 100%
      height: auto;
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: center;
      justify-content: center;
      height: 100vh;
      margin-right: auto;
      margin-left: auto;
      padding-left: auto;
      padding-right: auto;

      div {
        align-items: center;
        justify-content: center;
        padding-left: auto;
        padding-right: auto;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
        height: auto;
        text-align: center;

      }
      a {
        height: 100%;
      }
      img {
      }
    }
    /* aside {
      width: 100%;


    } */
  }
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding-left: auto;
  padding-right: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: auto;

  nav {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: auto;
    margin-right: auto;

    img {
      width: 60px;
      height: auto;
    }
    a {
      font-size: 16px;
      font-weight: bold;
      color: #fff;

      &:hover {
        transition: color 0.3s;
        color: #000;
      }
    }

  }

  ::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const Profile = styled.div`
  padding-left: 50px;
  @media only screen and (max-width: 768px) {
    padding-left: 0;
    display: flex;
    margin: auto;
    align-self: center;
    align-items: center;

    div {
      display: none;
      padding: 0px;
    }

    nav {
      width: auto;
      aside {
        display: flex;
        align: center;
        padding-left: 20px;

        img {
          width: 100%;
        }
      }
    }

    nav {
      a {
        margin-left: 0px;
      }
    }
  }

  display: flex;
  align-items: center;
  div {
    max-width: 100px;
    font-size: 12px;
    text-align: end;
    padding: 0 5px;
    strong {
      font-size: 16px;
      color: #000;
    }
    a {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      color: #fff;
      &:hover {
        transition: color 0.3s;
        color: #000;
      }
    }
  }
  img {
    width: 60px;
    height: auto;
    border-radius: 50%;
  }
`;
