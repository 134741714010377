import styled from 'styled-components';
import { darken } from 'polished';

export const Div = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  max-width: auto;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;

  table {
    div {
      margin-left: auto;
      margin-right: auto;
    }

    tbody {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
    thead {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }

    tbody {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }

  button {
    margin-left: auto;
    margin-right: auto;
    height: 44px;
    width: 400px;
    max-width: 90vw;
    background: rgba(0, 112, 0, 0.7);
    font-weight: bold;
    color: #fff;
    border: 0;
    padding: 0px 0px;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, 'rgba(0, 212, 0, 1)')};
    }
  }

  h3 {
    text-align: center;
  }
`;
