import React, { useState, useEffect } from "react";

import api from "~/services/api";

import { Container, Content } from "./styles";

import { toast } from "react-toastify";

import { FcInfo } from "react-icons/fc";

import Loading from "~/components/Loading";

import MUIDataTable from "mui-datatables";

function CubTrees() {
  const [dataToTable, setDataToTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [processed, setProcessed] = useState(false);

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    selectableRows: "none",
    fixedSelectColumn: false,
    fixedHeader: true,
    rowsPerPage: 25,
    rowsPerPageOptions: [25, 50, 100],
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    pagination: true,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro para o projeto.",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "TUDO",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
      selectedRows: {
        text: "Linhas selecionadas",
        delete: "Deletar",
        deleteAria: "Deletar linhas selecionadas",
      },
    },
  };

  const columns = [
    {
      label: "Talhão",
      name: "Talhão",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Parcela",
      name: "Parcela",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Árvore",
      name: "Árvore",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Altura total(m)",
      name: "Altura total(m)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Altura comercial (m)",
      name: "Altura comercial (m)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Seção",
      name: "Seção",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "hi (m)",
      name: "hi (m)",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "di com casca (cm)",
      name: "di (cm)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Volume da seção (m³)",
      name: "vi (m³)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Volume da Árvore (m³)",
      name: "Vt (m³)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Volume Comercial (m³)",
      name: "Vc (m³)",
      options: {
        filter: false,
        sort: true,
      },
    },
  ];

  /**
   * O handleData aqui no UseEffect será sempre acionado...
   * Então como farei para atualizar a listagem da Tabela que se encontra no projectData ?
   *
   * 1) Não lista nada na tabela enquanto não receber devolta os dados processados
   *
   * 2) Lista uma segunda tabela contendo os dados processados
   */

  function handleData(data) {
    const dataTable = [];

    // É melhor estruturar a consulta via backend como estava para árvores (Tree)

    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      for (let j = 0; j < element.stands.length; j++) {
        const subelement = element.stands[j];

        for (let k = 0; k < subelement.plots.length; k++) {
          const subelement2 = subelement.plots[k];

          for (let s = 0; s < subelement2.inventories.length; s++) {
            const subelement3 = subelement2.inventories[s];

            for (let z = 0; z < subelement3.trees.length; z++) {
              const subelement4 = subelement3.trees[z];

              for (let t = 0; t < subelement4.sections.length; t++) {
                const subelement5 = subelement4.sections[t];

                const dataItem = {
                  talhao: subelement.standname,
                  parc: subelement2.plotname,
                  arv: subelement4.tree,
                  dap: subelement4.treedap,
                  ht: subelement4.treeht,
                  hc: subelement4.treehc,
                  section: subelement5.section,
                  sectionhi: subelement5.sectionhi,
                  sectiondicc: subelement5.sectiondicc,
                  sectiondisc: subelement5.sectiondisc,
                };
                dataTable.push(dataItem);
              }
            }
          }
        }
      }
    }

    setDataToTable(dataTable);
  }

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function loadTreesFromSession() {
      const FulldataSession = sessionStorage.getItem("smalianData");

      if (FulldataSession) {
        setDataToTable(JSON.parse(FulldataSession));
        setProcessed(true);
      } else {
        const dataSession = JSON.parse(sessionStorage.getItem("projectData"));

        if (dataSession !== null) {
          handleData(dataSession);
        }
      }
    }
    loadTreesFromSession();
  }, []);

  async function handleSubmit() {
    setLoading(true);

    if (processed) {
      setLoading(false);
      return toast.warn("Dados do projeto já processados");
    }
    if (dataToTable.length > 0) {
      try {
        const response = await api.post("/smalian", dataToTable);

        setDataToTable(response.data);
        sessionStorage.setItem("smalianData", JSON.stringify(response.data));
        setLoading(false);
        setProcessed(true);
        toast.success("Volumes calculados com sucesso!");
      } catch (err) {
        console.log(err);
        setLoading(false);
        toast.error("Não foi possível efetuar os cálculos. Verifique os dados");
      }
    } else {
      setLoading(false);
      toast.error("Sem dados para análise.");
    }
  }

  return (
    <>
      <Container>
        <Loading loading={loading} />
        <Content>
          <div className="myDiv">
            <button type="submit" onClick={handleSubmit}>
              Calcular Volumes
            </button>
          </div>

          <div className="hide">
            <FcInfo size={25} />
            <h4>
              Calcula os volumes das seções e o volume total e comercial de cada
              árvore por Smalian.
            </h4>
          </div>
        </Content>

        <MUIDataTable
          title="Seções Amostradas"
          data={dataToTable.map((d) => {
            return [
              d.talhao,
              d.parc,
              d.arv,
              d.ht ? d.ht.toFixed(1) : null,
              d.hc ? d.hc.toFixed(1) : null,
              d.section,
              d.sectionhi ? d.sectionhi.toFixed(2) : null,
              d.sectiondicc != null ? d.sectiondicc.toFixed(1) : null,
              d.vsecao ? d.vsecao.toFixed(4) : null,
              d.VT ? d.VT.toFixed(4) : null,
              d.VC ? d.VC.toFixed(4) : null,
            ];
          })}
          columns={columns}
          options={options}
        />
      </Container>
    </>
  );
}

export default CubTrees;
