/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Form, Select } from '@rocketseat/unform';
import api from '~/services/api';

import { Container } from './styles';

function ServiceStep2(props) {
  const [collectors, setMycollectors] = useState([]);

  useEffect(() => {
    async function loadMyCollectors() {
      const response = await api.get('/collectors');
      const data = response.data.map(collector => ({
        ...collector,
      }));

      const CollectorOptions = data.map(e => {
        return {
          id: e.email,
          title: e.name,
        };
      });

      setMycollectors(CollectorOptions);
    }
    loadMyCollectors();
  }, []);

  function handleNextStep({ collector }) {
    sessionStorage.setItem('collectorSelected', JSON.stringify(collector));
    props.handleAdd();
  }

  return (
    <Container>
      <Form onSubmit={handleNextStep}>
        <div>
          <h3>2. Selecione o responsável pelo inventário:</h3>
          <Select
            required
            name="collector"
            options={collectors}
            placeholder="Coletor"
          />
        </div>
        <div>
          <button className="verde" type="submit">
            Salvar
          </button>
        </div>
      </Form>
    </Container>
  );
}

export default ServiceStep2;
