import styled from "styled-components";
import { darken } from "polished";

export const Container = styled.div`
  padding: 0 auto;
  width: auto;
  height: auto;
  margin: 0 auto;
  /* padding-bottom: 100px; */

  div.graphicArea {
    @media only screen and (max-width: 768px) {
      display: block;
      max-width: 80%;
    }
    display: flex;
    max-width: 900px;
    height: auto;
    margin: 0 auto;
    overflow: scroll;
    ::-webkit-scrollbar {
      display: none;
    }
    flex-direction: row;
    flex-wrap: wrap;

    div.graphics {
      display: flex;
      max-width: 400px;
      flex-direction: column;
      margin: 0 auto;
      align-items: center;
      text-align: center;

      div.recharts-responsive-container {
        display: flex;
        /* max-width: 350px;
        max-height: 400px; */
        /* background: #000; */
      }
      h2 {
        font-style: "roboto";
        font-weight: 700;
        color: #333;
        padding: 10px 0;
      }
    }

    /* padding-bottom: 100px; */
  }
`;

export const Content = styled.div`
  @media only screen and (max-width: 768px) {
    display: block;
  }

  .hide {
    display: flex;
    flex-direction: column;
    visibility: hidden;
    padding: 5px;
    align-items: center;
    align-content: center;
    flex-direction: row;

    div {
      h4 {
        color: blue;
        height: 100%;
        align-items: center;
        align-content: center;
      }
    }
  }

  .myDiv:hover + .hide {
    visibility: visible;
    position: relative;
    background: #ddd;
    border-radius: 5px;
  }

  width: 100vw;
  margin: 0 auto;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  button {
    height: 44px;
    max-width: 90vw;
    padding: 10px;
    margin: 5px;
    min-width: 100px;
    background: rgba(0, 0, 112, 0.7);
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, "rgba(0, 0, 212, 1)")};
    }
  }

  nav {
    display: flex;
    align-items: center;
    a {
      font-size: 16px;
      margin: 10px;
      font-weight: bold;
      color: #333;

      &:hover {
        transition: color 0.3s;
        color: #000;
      }
    }
  }

  form {
    select,
    input {
      width: 200px;
      background: rgba(0, 0, 0, 0.07);
      border: 0;
      border-radius: 4px;
      height: 44px;
      color: #000;
      margin: 10px;
      padding: 5px;
    }

    button.verde,
    button.azul {
      height: 44px;
      max-width: 200px;
      min-width: 100px;
      background: rgba(0, 112, 0, 0.7);
      font-weight: bold;
      color: #fff;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.03, "rgba(0, 212, 0, 1)")};
      }
    }

    button.azul {
      background: rgba(0, 0, 112, 0.7);
      &:hover {
        background: ${darken(0.03, "rgba(0, 0, 112, 1)")};
      }
    }
  }
`;
