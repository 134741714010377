import styled from 'styled-components';
import { darken } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  height: 100vh;
  overflow: scroll;

  button.stepper {
    height: 44px;
    margin-left: 10px;
    width: 100px;
    padding-left: auto;
    padding-right: auto;
    background: rgba(212, 212, 212, 0.2);
    font-weight: bold;
    color: #1976d2;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, 'rgba(25, 118, 210, 0.1)')};
    }
  }

  select {
    background: rgba(0, 0, 0, 0.07);
    border: 0;
    border-radius: 4px;
    height: 44px;
    color: rgba(0, 0, 0, 0.87);
    margin: 10px;
    padding: 5px;
  }

  div.Column {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 95vw;
    padding: 0;
    border: 0;
    margin: 0;
    /* margin-left: auto;
    margin-right: auto; */

    input, select {
      display: flex;
      width: 250px;
      max-width: 50vw;
      padding: 5px;
      margin-left: 10px;
      margin-right: auto;
      height: 44px;
      background: rgba(0, 0, 0, 0.07);
      /* font-weight: bold; */
      color: #000;
      border: 0;
      border-radius: 4px;
      font-size: 14px;
    }

    div {
      display: flex;
      flex-direction: row;
    }
  }

  div.Row {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0;
    border: 0;
    margin: 0;


    div {
      display: flex;
    }
  }

  div {
    align-content: center;
    width: auto;
    padding: 5px;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;


    input {
      width: 100%;
      padding: 5px;
      margin-left: auto;
      margin-right: auto;
      height: 44px;
      background: rgba(0, 0, 0, 0.07);
      /* font-weight: bold; */
      color: #000;
      border: 0;
      border-radius: 4px;
      font-size: 14px;

      p {
        color: #fff;
      }
    }

    label {
      div {
        align-content: center;
        width: auto;
        margin-top: 5px;
        height: auto;
        background: rgba(0, 0, 112, 0.7);
        font-weight: bold;
        color: #fff;
        border-radius: 4px;
        font-size: 16px;
        transition: background 0.2s;
        &:hover {
          background: ${darken(0.03, 'rgba(0, 0, 212, 1)')};
        }
      }
    }

    input[type="file"] {
      display: none;
    }

    }
  }

  form {
    width: 100%
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 20px;
    font-size: 18px;

    select {
      width: 100%;
      background: rgba(0, 0, 0, 0.07);
      font-size: 18px;
      border: 0;
      border-radius: 4px;
      height: 44px;
      color: rgba(0, 0, 0, 0.87);
      margin: 10px;
      padding: 5px;
      font-size: 18px;
    }

    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-content: center;
      box-shadow: none;
      margin: 0;
      border: 0;
      padding: 0;
      background-color: rgb(245, 245, 245);

      span {
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        padding: 15px;
        display: flex
        flex-direction: column;
        text-align: left;
        color: red;
      }
    }
  button {
    padding-left: auto;
    padding-right: auto;
    height: 44px;
    width: 400px;
    max-width: 100%;
    background: rgba(0, 112, 0, 0.7);
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;
    &:hover {
      background: ${darken(0.03, 'rgba(0, 212, 0, 1)')};
    }
  }

  }

  img {
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 3px solid rgba(255, 255, 255, 0.3);
    background: #eee;
  }
  table {
    display: inline-block;
    margin: 0 auto;
    padding-bottom: 0px;
    border-spacing: 0px;

    span {
      font-weight: bold;
      font-size: 20px;
    }

    div {
      text-align: left;
    }
  }

  tr:hover {
    background-color: rgb(245, 245, 245);
  }
  tr,
  th,
  td {
    margin-left: auto;
    margin-right: auto;
    padding: 15px;
    border: 0px;
    text-align: center;
    vertical-align: center;
    font-size: 24px;
    border-bottom: 1px solid #ccc;
  }

  th {
    font-size: 18px;
    padding-top: 25px;
  }

  td {
    text-align: center;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 400;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(0, 112, 0, 1);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: ${darken(0.03, 'rgba(0, 112, 0, 0.7)')};
  }
`;
