import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100vh;
  /* max-height: 900px; */
  /* background: linear-gradient(135deg, #fff, #7c7); */
  background-color: whitesmoke;
  z-index: 0;
  overflow: scroll;
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100vh;
  text-align: center;
  overflow: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  form {
    display: flex;
    flex-direction: row;
    input {
      background: rgba(0, 0, 0, 0.07);
      border: 0;
      border-radius: 4px;
      height: 44px;
      color: #000;
      margin: 10px;
      padding: 5px;
      &::placeholder {
        color: rgba(0, 0, 0, 0.7);
      }
    }
    span {
      color: #333;
      align-self: flex-end;
      margin: 0 0 10px;
      font-weight: bold;
    }
    button {
      margin: 10px;
      padding: 5px;
      height: 44px;
      background: #00c000;
      font-weight: bold;
      color: #000;
      border: 0;
      border-radius: 4px;
      font-size: 16px;
      transition: background 0.2s;
      &:hover {
        background: ${darken(0.05, '#00c000')};
      }
    }
    a {
      color: #000;
      margin-top: 15px;
      font-size: 16px;
      opacity: 0.8;
      &:hover {
        opacity: 1;
      }
    }
  }
`;
