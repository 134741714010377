/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-return-assign */
import React, { useState } from "react";
import { Form } from "@rocketseat/unform";
import { toast } from "react-toastify";
// import { useSelector } from 'react-redux';

import history from "~/services/history";

import Loading from "~/components/Loading";
import api from "~/services/api";

// import { Container } from './styles';

function Step3() {
  const [loading, setLoading] = useState(false);
  const ProjectData = JSON.parse(sessionStorage.getItem("projectData"));
  const StandAndPlotData = JSON.parse(sessionStorage.getItem("StandsAndPlots"));
  // const manager_id = useSelector(state => state.manager.profile.uuid);
  // Isso tenho que pegar do backend a partir do JSONWebTOken

  function onlyUnique(value, index, self) {
    // console.log(value, index, self);
    // console.log(self.indexOf(value) === index);
    // if (self.indexOf(value) === index) {
    //   console.log(index);
    // }
    return self.indexOf(value) === index;
  }

  function handleData() {
    const standNameList = StandAndPlotData.map((s) => s.nomeTal);
    const standAreaList = StandAndPlotData.map((s) => s.areaTal);

    const UniqueStandList = standNameList.filter(onlyUnique);
    const UniqueAreaStandList = standAreaList.filter(onlyUnique);

    const arrayStands = [];

    for (const i in UniqueStandList) {
      const novo = StandAndPlotData.filter(
        (ele) => ele.nomeTal === UniqueStandList[i]
      );

      const stand = novo.map((s) => {
        return {
          plotname: s.nomeParc,
          plotarea: JSON.parse(s.areaParc),
          coordx: JSON.parse(s.latitude),
          coordy: JSON.parse(s.longitude),
        };
      });

      arrayStands.push({
        standname: UniqueStandList[i],
        standarea: UniqueAreaStandList[i],
        plots: stand,
      });
    }

    const farm = {
      projectname: ProjectData.projectname,
      projectowner: ProjectData.projectowner,
      projectcity: ProjectData.projectcity,
      projectstate: ProjectData.projectstate,
      // manager_id,
      projectcoordx: ProjectData.coordx,
      projectcoordy: ProjectData.coordy,
      stands: arrayStands,
    };

    api.post("/myprojects", farm);
  }

  // const Project = {
  //   projectname: ProjectData.projectname,
  //   projectowner: ProjectData.name,
  //   projectcoordx: ProjectData.coordx,
  //   projectcoordy: ProjectData.coordy,
  //   stands: [UniqueStandList.map(e => e)],
  // };

  function handleSubmitData() {
    setLoading(true);
    try {
      handleData();
      setLoading(false);
      toast.success("Projeto cadastrado com sucesso!");
      sessionStorage.removeItem("projectData");
      sessionStorage.removeItem("StandsAndPlots");
      sessionStorage.removeItem("ProjectsInfo");
      sessionStorage.removeItem("ProjectsRefreshTime");
      history.push("/projectsManager");
    } catch (err) {
      toast.error("Falha no cadastro do projeto!");
    }
  }

  return (
    <>
      <Loading loading={loading} />
      <div>
        <br />
        <h2>Verifique se os dados estão corretos:</h2>
        <table>
          <thead>
            <tr>
              <th>Projeto</th>
              <th>Talhão</th>
              <th>Área do Talhão (ha)</th>
              <th>Parcela</th>
              <th>Área da Parcela (m²)</th>
              <th>Latitude</th>
              <th>Longitude</th>
            </tr>
          </thead>
          <tbody>
            {StandAndPlotData.map((element) => (
              <>
                <tr>
                  <td>{ProjectData.projectname}</td>
                  <td>{element.nomeTal}</td>
                  <td>{element.areaTal}</td>
                  <td>{element.nomeParc}</td>
                  <td>{element.areaParc}</td>
                  <td>{element.latitude}</td>
                  <td>{element.longitude}</td>
                </tr>
              </>
            ))}
          </tbody>
        </table>
      </div>
      <Form onSubmit={handleSubmitData}>
        <div>
          <button className="verde" type="submit">
            <h3>Gravar o Projeto</h3>
          </button>
        </div>
      </Form>
    </>
  );
}

export default Step3;
