/* eslint-disable react/prop-types */
import React, { forwardRef, useState } from "react";

import { Container, Content } from "./styles";

import { Select } from "@rocketseat/unform";

import Loading from "~/components/Loading";

import MUIDataTable from "mui-datatables";

import api from "~/services/api";

import { FcInfo } from "react-icons/fc";

import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ComposedChart,
  Line,
  Label,
  Legend,
  Scatter,
} from "recharts";

import { toast } from "react-toastify";
// import { useEffect } from "react";

// eslint-disable-next-line no-unused-vars
const Step2 = forwardRef((props, ref) => {
  let B = JSON.parse(sessionStorage.getItem("HDTable"));
  let C = JSON.parse(sessionStorage.getItem("DataToPlot2"));
  let D = JSON.parse(sessionStorage.getItem("dataTrees"));
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const DataToPlot2 = C ? C : [];
  const [search, setSearch] = useState("");
  const [modelParams, setModelParams] = useState({});

  const loading = false;

  function sortearPorDap(lista) {
    return lista.sort(function(a, b) {
      return a.dap - b.dap;
    });
  }

  const dataTrees = D ? sortearPorDap(D) : [];

  const [dataTable, setDataTable] = useState(B ? B : dataTrees);

  const options = {
    filterType: "dropdown",
    responsive: "standard",
    print: false,
    selectableRows: "none",
    fixedSelectColumn: false,
    fixedHeader: true,
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    downloadOptions: {
      filterOptions: {
        useDisplayedRowsOnly: true,
        useDisplayedColumnsOnly: true,
      },
    },
    pagination: true,
    textLabels: {
      body: {
        noMatch: "Desculpe, nenhum registro para o projeto.",
        toolTip: "Ordenar",
        columnHeaderTooltip: (column) => `Ordenar por ${column.label}`,
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Buscar",
        downloadCsv: "Download CSV",
        print: "Imprimir",
        viewColumns: "Ver Colunas",
        filterTable: "Filtrar tabela",
      },
      filter: {
        all: "TUDO",
        title: "FILTROS",
        reset: "RESETAR",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
      selectedRows: {
        text: "Linhas selecionadas",
        delete: "Deletar",
        deleteAria: "Deletar linhas selecionadas",
      },
    },
  };

  const columns = [
    {
      label: "Talhão",
      name: "Talhão",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Parcela",
      name: "Parcela",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      label: "Árvore",
      name: "Árvore",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "Date",
      name: "Data",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "DAP (cm)",
      name: "DAP (cm)",
      options: {
        filter: false,
        sort: true,
      },
    },
    {
      label: "HT (cm)",
      name: "HT (m)",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const defaultInvOption = {
    id: "",
    title: "Todos Inventários",
  };

  const inventoryOptions = DataToPlot2.map((e) => {
    return {
      id: e.plot,
      title: e.plot,
    };
  });

  inventoryOptions.unshift(defaultInvOption);

  const dataTreesFiltered =
    search.length !== ""
      ? dataTrees.filter((p) => p.date.includes(search))
      : dataTrees;

  const dataPlotFiltered =
    search.length > 0
      ? DataToPlot2.filter((p) => p.plot.includes(search))
      : DataToPlot2;

  const hasData = true;

  let getName = (data) => {
    return data.ht;
  };

  let stoffels = (data) => {
    const Yest = Math.exp(modelParams.bo + modelParams.b1 * Math.log(data.dap));

    return Yest;
  };

  let curtis = (data) => {
    const Yest = Math.exp(modelParams.bo + modelParams.b1 * (1 / data.dap));

    return Yest;
  };

  const COLORS = [
    "#0000FF",
    "#FF0000",
    "#008000",
    "#8a2be2",
    "#800000",
    "#00FF00",
    "#FF8042",
    "#003366",
    "#FF00cc",
    "#800080",
    "#57A0D2",
    "#00C49F",
    "#065535",
    "#4682B4",
    "#FFD700",
    "#A07000",
    "#CD7F32",
    "#AAAAAA",
    "#BEC2CB",
    "#000000",
  ];

  // Esta função é usada pelo pai
  function handleSaveData() {
    if (modelParams.equation) {
      props.handleAdd();
    } else {
      toast.info("Dados do projeto sem alturas estimadas");
    }
  }

  // const maxX = Math.max(...dataTrees.map((e) => e.dap));
  // const minX = Math.min(...dataTrees.map((e) => e.dap));
  const maxY = Math.round(Math.max(...dataTrees.map((e) => e.ht)) * 1.2, 1);
  const minY = Math.round(Math.max(...dataTrees.map((e) => e.ht)) * 0.4, 1);
  // const maxY = Math.round(
  //   Math.exp(modelParams.bo + modelParams.b1 * (1 / maxX)),
  //   1
  // );
  // const minY = Math.round(
  //   Math.exp(modelParams.bo + modelParams.b1 * (1 / minX)),
  //   1
  // );

  async function handleAjust() {
    const data = dataTreesFiltered;

    try {
      const response = await api.post("/hipsom", data);

      const newModelParams = {
        equation: response.data[1]["b0"],
        bo: Number(response.data[0]["b0"]),
        b1: Number(response.data[0]["b1"]),
      };

      const Table = data.map((element) => {
        const ht = element.ht
          ? element.ht
          : Number(
              response.data[1]["b0"].includes("log")
                ? stoffels(element)
                : curtis(element)
            );

        return {
          talhao: element.talhao,
          parc: element.parc,
          arv: element.arv,
          date: element.date,
          dap: element.dap,
          ht: ht,
        };
      });

      setModelParams(newModelParams);
      setDataTable(sortearPorDap(Table));
      sessionStorage.setItem("HDTable", JSON.stringify(sortearPorDap(Table)));

      toast.success("Modelo ajustado com sucesso!");
    } catch (error) {
      toast.error("Erro de processamento");
    }
  }

  // Agora está atualizando tudo com o simples Select Não é isso que eu quero

  // useEffect(() => {
  //   handleAjust();
  // }, [search]);

  function selectInventory(selectedValue) {
    if (selectedValue === "Todos Inventários") {
      setSearch("");
    }

    setSearch(selectedValue);
  }

  return (
    <>
      <Content>
        <Loading loading={loading} />
      </Content>

      {hasData ? (
        <>
          <Container>
            <div className="graphic">
              <h2>Relação Hipsométrica</h2>
              {/* Criar uma caixa de seleção para selecionar as parcelas */}
              <Select
                name="Data do Inventário"
                options={inventoryOptions}
                placeholder="Todos Inventários"
                onChange={(e) => selectInventory(e.target.value)}
              />
              <ResponsiveContainer width="100%" height={400}>
                <ComposedChart
                  margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <CartesianGrid />
                  <Tooltip cursor={{ strokeDasharray: "3 3" }} />

                  <XAxis
                    type="number"
                    dataKey="dap"
                    name="DAP"
                    unit="cm"
                    position="end"
                    domain={["auto", "auto"]}
                    interval={"preserveStartEnd"}
                    tickCount={8}
                    allowDecimals={true}
                  >
                    <Label
                      value={"Diâmetro à Altura do Peito (cm)"}
                      position="bottom"
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                    />
                  </XAxis>
                  <XAxis dataKey={getName} />
                  <YAxis
                    type="number"
                    dataKey="ht"
                    name="HT"
                    unit="m"
                    domain={[minY ? minY : "auto", maxY ? maxY : "auto"]}
                    // domain={["auto", "auto"]}
                    interval={"preserveStartEnd"}
                  >
                    <Label
                      style={{
                        textAnchor: "middle",
                        fontSize: "130%",
                        fill: "black",
                      }}
                      position="left"
                      angle={270}
                      value={"Altura Total (m)"}
                    />
                  </YAxis>

                  <Legend verticalAlign="top" height={36} />

                  {dataPlotFiltered.map((item, index) => (
                    <>
                      <Scatter
                        key={`cell-${index}`}
                        name={item.plot}
                        data={item.data}
                        fill={COLORS[index]}
                      />
                      {/* Plotando linhas */}
                    </>
                  ))}
                  {modelParams.equation ? (
                    <>
                      {" "}
                      <Line
                        name={modelParams.equation}
                        type="monotone"
                        dot={false}
                        dataKey={
                          modelParams.equation.includes("log") // colocar o nome do modelo
                            ? stoffels
                            : curtis
                        }
                        data={dataTreesFiltered}
                        stroke={COLORS[3]}
                        strokeDasharray="4 1 2"
                      ></Line>
                    </>
                  ) : (
                    <></>
                  )}
                </ComposedChart>
              </ResponsiveContainer>
            </div>

            <div className="myDiv">
              <button className="azul" type="submit" onClick={handleAjust}>
                <h3>Ajustar modelo hipsométrico</h3>
              </button>
            </div>
            <div className="hide">
              <FcInfo size={25} />
              <h4>
                A partir dos pares de dados de DAP e HT é ajustado um modelo
                hipsométrico.
              </h4>
            </div>

            <MUIDataTable
              title="Alturas para árvores amostradas"
              data={dataTable.map((d) => {
                return [
                  d.talhao,
                  d.arv,
                  d.parc,
                  d.date ? d.date : null,
                  d.dap,
                  d.ht ? d.ht.toFixed(1) : null,

                  /** O data table não atualiza com o handleAdjust */
                  // : !modelParams.equation
                  // ? null
                  // : modelParams.equation.includes("log")
                  // ? stoffels(d).toFixed(2)
                  // : curtis(d).toFixed(2),
                ];
              })}
              columns={columns}
              options={options}
            />
            <div className="myDiv">
              <button className="verde" type="submit" onClick={handleSaveData}>
                <h3>Avançar para 3ª etapa</h3>
              </button>
            </div>
            <div className="hide">
              <FcInfo size={25} />
              <h4>
                Avança para próxima etapa de processamento com os dados
                consistidos.
              </h4>
            </div>
          </Container>
        </>
      ) : (
        <>
          <div>
            <br />
            <p>Nenhum projeto carregado</p>
          </div>
        </>
      )}
    </>
  );
});

export default Step2;
