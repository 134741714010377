import styled from "styled-components";
// import { darken } from 'polished';

export const Container = styled.div`
  display: block;
  width: 900px;
  max-width: 100vw;
  height: auto
  margin-left: auto;
  margin-right: auto;
  max-width: 100vw;
  align-items: center;
  padding-left: auto;
  padding-right: auto;
  padding-bottom: 100px;

  div {
    /* padding-top: 10px; */
    width: auto;
    margin-left: auto;
    margin-right: auto;
  }

  div.table {
    height: auto;
    max-width: 100vw;
    overflow: scroll;

    div {
      overflow: scroll;

      ::-webkit-scrollbar {
        display: none;
      }

    }
    box-shadow: none;
    ::-webkit-scrollbar {
      display: none;
    }
    tr:hover {
      background: rgba(0,0,0, 0.05);
      cursor: pointer;
    }
  }

`;
